import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themeSelector } from "../../store/reducers/domainTheme";
import { setColorPalette } from "../utils";

const initialColors = {
	primary_color: '#6B4DFF',
	bg_color: '#FBFAFF',
	secondaryBackground: '#F6F5FF',
	secondaryLightPurple: '#E2E0FF',
	secondaryGray: '#A4A1B2',
};

export function NoItemsImage(props) {
	const [colors, setColors] = useState(initialColors);

	const theme = useSelector(themeSelector);

	useEffect(() => {
		if (theme?.title) {
			const newColors = setColorPalette(theme);
			setColors(newColors);
		}
	}, [theme]);

	return (
			<svg
			width="353"
			height="279"
			viewBox="0 0 353 279"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g filter="url(#filter0_d_11714_62285)">
				<path
					d="M171.5 155.712C161.612 155.712 152.775 160.525 147.262 167.875C141.75 160.525 132.912 155.712 123.025 155.712C106.312 155.712 92.75 169.362 92.75 186.162C92.75 192.637 93.8 198.675 95.55 204.187C104.125 231.4 130.725 247.762 143.85 252.225C145.687 252.837 148.75 252.837 150.587 252.225C163.712 247.762 190.312 231.487 198.887 204.187C200.725 198.587 201.687 192.637 201.687 186.162C201.775 169.362 188.212 155.712 171.5 155.712Z"
					fill={colors?.secondaryLightPurple}
				/>
			</g>
			<g filter="url(#filter1_d_11714_62285)">
				<path
					d="M188.562 141.972C188.562 143.984 186.55 145.297 184.625 144.772C172.812 141.709 159.862 144.247 150.062 151.247C148.137 152.647 145.512 152.647 143.675 151.247C136.762 146.172 128.362 143.372 119.525 143.372C96.9499 143.372 78.575 161.834 78.575 184.584C78.575 209.259 90.3875 227.721 102.287 240.059C102.9 240.671 102.375 241.721 101.587 241.371C77.7 233.234 24.5 199.459 24.5 141.972C24.5 116.597 44.8875 96.1216 70.0875 96.1216C85.05 96.1216 98.2624 103.297 106.575 114.409C114.975 103.297 128.187 96.1216 143.062 96.1216C168.175 96.1216 188.562 116.597 188.562 141.972Z"
					fill={colors?.secondaryLightPurple}
				/>
			</g>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M238.959 145.941C214.133 177.981 168.035 183.828 135.996 159.002C103.957 134.177 98.1097 88.0786 122.935 56.0395C147.761 24.0004 193.859 18.1528 225.898 42.9785C257.937 67.8042 263.785 113.902 238.959 145.941Z"
				fill={colors?.secondaryBackground}
			/>
			<g filter="url(#filter2_d_11714_62285)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M251.82 174.807C246.036 170.325 237.928 170.801 231.902 174.953C200.533 196.57 157.56 196.538 125.838 171.958C86.5935 141.549 79.4308 85.0832 109.84 45.8384C140.249 6.59352 196.715 -0.569233 235.959 29.8399C267.681 54.4198 278.442 96.024 265.342 131.797C262.826 138.669 264.389 146.638 270.174 151.121L332.194 199.177C338.734 204.245 339.928 213.656 334.86 220.197C329.792 226.738 320.381 227.932 313.84 222.863L251.82 174.807ZM238.93 145.865C214.096 177.915 167.983 183.765 135.933 158.931C103.883 134.096 98.0333 87.9828 122.867 55.9328C147.701 23.8829 193.815 18.0333 225.865 42.8674C257.915 67.7015 263.764 113.815 238.93 145.865Z"
					fill={colors?.bg_color}
				/>
			</g>
			<mask
				id="mask0_11714_62285"
				style={{maskType:"alpha"}}
				maskUnits="userSpaceOnUse"
				x="108"
				y="28"
				width="148"
				height="148"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M240.126 146.604C215.263 178.692 169.095 184.548 137.008 159.685C104.921 134.822 99.0642 88.654 123.927 56.5666C148.791 24.4791 194.958 18.6226 227.046 43.4859C259.133 68.3491 264.989 114.517 240.126 146.604Z"
					fill={colors?.bg_color}
				/>
			</mask>
			<g mask="url(#mask0_11714_62285)">
				<path
					d="M180 153.1C168.7 153.1 158.6 158.6 152.3 167C146 158.6 135.9 153.1 124.6 153.1C105.5 153.1 90 168.7 90 187.9C90 195.3 91.2 202.2 93.2 208.5C103 239.6 133.4 258.3 148.4 263.4C150.5 264.1 154 264.1 156.1 263.4C171.1 258.3 201.5 239.7 211.3 208.5C213.4 202.1 214.5 195.3 214.5 187.9C214.6 168.7 199.1 153.1 180 153.1Z"
					stroke={colors?.secondaryLightPurple}
					strokeWidth="3"
					strokeLinecap="round"
					strokeLinejoin="bevel"
					strokeDasharray="12 12"
				/>
				<path
					d="M199.5 137.396C199.5 139.696 197.2 141.196 195 140.596C181.5 137.096 166.7 139.996 155.5 147.996C153.3 149.596 150.3 149.596 148.2 147.996C140.3 142.196 130.7 138.996 120.6 138.996C94.7999 138.996 73.8 160.096 73.8 186.096C73.8 214.296 87.2999 235.396 100.9 249.496C101.6 250.196 101 251.396 100.1 250.996C72.8 241.696 12 203.096 12 137.396C12 108.396 35.3 84.9961 64.1 84.9961C81.1999 84.9961 96.2999 93.1961 105.8 105.896C115.4 93.1961 130.5 84.9961 147.5 84.9961C176.2 84.9961 199.5 108.396 199.5 137.396Z"
					stroke={colors?.secondaryLightPurple}
					strokeWidth="4"
					strokeDasharray="16 16"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_11714_62285"
					x="74.75"
					y="143.712"
					width="144.937"
					height="132.972"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="6" />
					<feGaussianBlur stdDeviation="8.99999" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.08 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_11714_62285"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_11714_62285"
						result="shape"
					/>
				</filter>
				<filter
					id="filter1_d_11714_62285"
					x="0.5"
					y="80.1216"
					width="212.062"
					height="193.32"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="8" />
					<feGaussianBlur stdDeviation="12" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.08 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_11714_62285"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_11714_62285"
						result="shape"
					/>
				</filter>
				<filter
					id="filter2_d_11714_62285"
					x="76.0176"
					y="0.512286"
					width="276.965"
					height="244.968"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4.49473" />
					<feGaussianBlur stdDeviation="7.49122" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.08 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_11714_62285"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_11714_62285"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	
	);
}
