import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themeSelector } from "../../../store/reducers/domainTheme";
import { setColorPalette } from "../../utils";

const initialColors = {
  primary_color: "#6B4DFF",
  bg_color: "#FBFAFF",
  secondaryBackground: "#F6F5FF",
  secondaryLightPurple: "#E2E0FF",
  secondaryGray: "#A4A1B2",
};

export function PasswordResetImage(props) {
  const [colors, setColors] = useState(initialColors);

  const theme = useSelector(themeSelector);

  useEffect(() => {
    if (theme?.title) {
      const newColors = setColorPalette(theme);
      setColors(newColors);
    }
  }, [theme]);

  return (
    <svg
      width="304"
      height="315"
      viewBox="0 0 304 315"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.6 134.349V102.47C254.6 58.4458 243.96 0 152 0C60.04 0 49.4 58.4458 49.4 102.47V134.349C10.64 139.055 0 158.031 0 208.735V239.096C0 299.819 15.2 315 76 315H228C288.8 315 304 299.819 304 239.096V208.735C304 158.031 293.36 139.055 254.6 134.349ZM231.8 132.831V102.47C231.8 54.6506 218.272 22.7711 152 22.7711C85.728 22.7711 72.2 54.6506 72.2 102.47V132.831H231.8ZM91.2 239.096C95.152 239.096 99.104 237.427 101.992 234.694C104.728 231.81 106.4 227.863 106.4 223.916C106.4 221.942 105.944 219.969 105.184 218.147C104.424 216.325 103.36 214.655 101.992 213.137C97.736 208.887 91.048 207.52 85.424 209.949C83.448 210.708 81.928 211.771 80.408 213.137C79.04 214.655 77.976 216.325 77.216 218.147C76.456 219.969 76 221.942 76 223.916C76 227.863 77.672 231.81 80.408 234.694C81.928 236.06 83.448 237.123 85.424 237.882C87.248 238.641 89.224 239.096 91.2 239.096ZM162.792 234.694C164.16 233.176 165.224 231.506 165.984 229.684C166.744 227.863 167.2 225.889 167.2 223.916C167.2 219.817 165.528 216.022 162.792 213.137C157.016 207.52 146.832 207.52 141.208 213.137C138.472 216.022 136.8 219.817 136.8 223.916C136.8 225.889 137.256 227.863 138.016 229.684C138.776 231.506 139.84 233.176 141.208 234.694C144.096 237.427 147.896 239.096 152 239.096C155.952 239.096 159.904 237.427 162.792 234.694ZM212.8 239.096C216.752 239.096 220.704 237.427 223.592 234.694C226.328 231.81 228 227.863 228 223.916C228 223.005 227.848 221.942 227.696 220.88C227.544 219.969 227.24 219.058 226.784 218.147C226.48 217.236 226.024 216.325 225.416 215.566C224.808 214.656 224.2 213.897 223.593 213.138L223.592 213.137C217.968 207.52 207.784 207.52 202.008 213.137C199.272 216.022 197.6 219.817 197.6 223.916C197.6 228.014 199.272 231.81 202.008 234.694C204.896 237.427 208.848 239.096 212.8 239.096Z"
        fill={colors?.secondaryLightPurple}
      />
    </svg>
  );
}
