import { useSelector } from "react-redux"
import { Outlet } from "react-router-dom"
import { themeSelector } from "../../store/reducers/domainTheme"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import Logo from "../Logo/Logo"
import Footer from "./Footer/Footer"
import Header from "./Header"
import styles from "./Layout.module.sass"

const LayoutFullWidth = () => {
	const theme = useSelector(themeSelector)
	return (
		<div className={styles.wrapper}>
			<Header logo={theme?.logo?.small} />
			<Breadcrumbs />
			<Outlet />
			<Footer
				logo={Logo}
				email={theme?.support_email}
				phone={theme?.support_phone}
			/>
		</div>
	)
}

export { LayoutFullWidth }
