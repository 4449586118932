import { createAction, handleActions } from "redux-actions"
import { all, call, put, take } from "@redux-saga/core/effects"
import {
	loginRequest,
	logoutRequest,
	autologinRequest,
	onBoardingCheckRequest, onBoardingPasswordRequest
} from "../../api/auth/auth"
import { setUser } from "./user"
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader"
import { setNegate } from "./domainTheme"
import {
	successMessage,
	dangerMessage,
} from "../../components/UIKit/alert/alertReducer"

const namespace = "auth"

const initialState = {
	auth: false,
	token: null,
	error: null,
}

const LOGIN = `${namespace}/LOGIN`
const LOGOUT = `${namespace}/LOGOUT`
const GET_TOKENS = `${namespace}/GET_TOKENS`
const SET_TOKEN = `${namespace}/SET_TOKEN`
const SET_AUTH = `${namespace}/SET_AUTH`
const SET_AUTH_ERROR = `${namespace}/SET_AUTH_ERROR`
const AUTO_LOGIN = `${namespace}/AUTO_LOGIN`
const ONBOARDING_CHECK = `${namespace}/ONBOARDING_CHECK`
const ONBOARDING_PASSWORD = `${namespace}/ONBOARDING_PASSWORD`
export const login = createAction(LOGIN)
export const logout = createAction(LOGOUT)
export const getTokens = createAction(GET_TOKENS)
export const setToken = createAction(SET_TOKEN)
export const setAuth = createAction(SET_AUTH)
export const setAuthError = createAction(SET_AUTH_ERROR)
export const autologin = createAction(AUTO_LOGIN)
export const onBoardingCheck = createAction(ONBOARDING_CHECK)
export const onBoardingPassword = createAction(ONBOARDING_PASSWORD)

export default handleActions(
	{
		[SET_TOKEN]: (state, { payload }) => ({
			...state,
			token: payload,
			auth: !!payload,
		}),
		[LOGOUT]: () => initialState,
		[SET_AUTH_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	},
	initialState
)

export const authSelector = (state) => state[namespace].auth
export const authErrorSelector = (state) => state[namespace].error

function* init() {
	const token = localStorage.getItem("token")
	if (token) {
		yield put(setToken(token))
	}

	const negate = JSON.parse(localStorage.getItem("negate")) ?? false
	yield put(setNegate(negate))
}

function* logoutSaga() {
	while (true) {
		yield take(LOGOUT)
		try {
			yield put(setToken(null))
			const { data } = yield call(logoutRequest)
			if (data?.message) {
				yield put(successMessage(data?.message))
			}
		} catch (err) {
			err && console.log(err)
			if (err?.response?.data?.message) {
				yield put(dangerMessage(err?.response?.data?.message))
			}
		} finally {
			localStorage.removeItem("token")
			localStorage.removeItem("refresh_token")
		}
	}
}

function* loginSaga() {
	while (true) {
		const {
			payload: { values, cb, setError },
		} = yield take(LOGIN)
		yield put(addGlobalLoadItem(LOGIN))
		try {
			const { data } = yield call(loginRequest, values)
			if (
				data?.authorization?.access_token &&
				data?.authorization?.refresh_token
			) {
				localStorage.setItem("token", data?.authorization?.access_token)
				localStorage.setItem(
					"refresh_token",
					data?.authorization?.refresh_token
				)
				yield put(setToken(data?.authorization?.access_token))
				yield put(setUser(data?.person))

				if (typeof cb === "function") {
					cb()
				}
			}
		} catch (err) {
			if(err?.response?.status === 422){
				setError("email", " ")
				setError("password", err?.response?.data?.message)
			}
			if(err?.response?.status === 404){
				yield put(dangerMessage(err?.response?.data?.message))
			}
		} finally {
			yield put(removeGlobalLoadItem(LOGIN))
		}
	}
}

function* autologinSaga() {
	while (true) {
		const {
			payload: { token, cb },
		} = yield take(AUTO_LOGIN)

		localStorage.removeItem("token")
		localStorage.removeItem("refresh_token")
		
		yield put(addGlobalLoadItem(AUTO_LOGIN))
		try {
			const { data } = yield call(autologinRequest, token)

			if (
				data?.authorization?.access_token &&
				data?.authorization?.refresh_token
			) {
				localStorage.setItem("token", data?.authorization?.access_token)
				localStorage.setItem(
					"refresh_token",
					data?.authorization?.refresh_token
				)
				yield put(setToken(data?.authorization?.access_token))
			}

			if (data?.person) {
				yield put(setUser(data?.person))
			}

			cb()
		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem(AUTO_LOGIN))
		}
	}
}

function* onBoardingCheckSaga() {
	while (true) {
		const { payload } = yield take(ONBOARDING_CHECK)
 
		try {
			const { data } = yield call(onBoardingCheckRequest, payload)
		} catch (err) {
			err && console.log(err) 

			const redirect = err?.response?.data?.redirect ? new URL(err?.response?.data?.redirect).pathname : null
 
			if(redirect) {
				payload.navigate(redirect)
			} else {
				payload.navigate('/')
			}
		} 
	}
}

function* onBoardingPasswordSaga() {
	while (true) {
		const { payload } = yield take(ONBOARDING_PASSWORD)
 
		try {
			const { data } = yield call(onBoardingPasswordRequest, payload)

			localStorage.setItem("token", data?.authorization?.access_token)
			localStorage.setItem("refresh_token", data?.authorization?.refresh_token)
			yield put(setToken(data?.authorization?.access_token))
			payload.setStep(s => s + 1)

 
			const redirect = data?.redirect ? new URL(data?.redirect).pathname : null
			
			if(redirect){
				//payload.setRedirectUrl(redirect)
			}
		} catch (err) {
			err && console.log(err)
			payload.navigate('/')
		} 
	}
}

export function* authSagas() {
	yield all([init(), logoutSaga(), loginSaga(), autologinSaga(), onBoardingCheckSaga(), onBoardingPasswordSaga()])
}
