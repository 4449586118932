import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themeSelector } from "../../store/reducers/domainTheme";
import { setColorPalette } from "../utils";

const initialColors = {
  primary_color: "#6B4DFF",
  bg_color: "#FBFAFF",
  secondaryBackground: "#F6F5FF",
  secondaryLightPurple: "#E2E0FF",
  secondaryGray: "#A4A1B2",
};

export function NoItemsImage(props) {
  const [colors, setColors] = useState(initialColors);

  const theme = useSelector(themeSelector);

  useEffect(() => {
    if (theme?.title) {
      const newColors = setColorPalette(theme);
      setColors(newColors);
    }
  }, [theme]);

  return (
    <svg
      width="331"
      height="268"
      viewBox="0 0 331 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M148.75 79.2597H61.25C35 79.2597 17.5 96.7597 17.5 123.01V175.51C17.5 201.76 35 219.26 61.25 219.26V237.897C61.25 244.897 69.0375 249.097 74.8125 245.16L113.75 219.26H148.75C175 219.26 192.5 201.76 192.5 175.51V123.01C192.5 96.7597 175 79.2597 148.75 79.2597ZM105 185.747C101.325 185.747 98.4374 182.772 98.4374 179.185C98.4374 175.597 101.325 172.622 105 172.622C108.675 172.622 111.562 175.597 111.562 179.185C111.562 182.772 108.675 185.747 105 185.747ZM116.025 149.435C112.612 151.71 111.562 153.197 111.562 155.647V157.485C111.562 161.072 108.587 164.047 105 164.047C101.412 164.047 98.4374 161.072 98.4374 157.485V155.647C98.4374 145.497 105.875 140.51 108.675 138.585C111.912 136.397 112.962 134.91 112.962 132.635C112.962 128.26 109.375 124.672 105 124.672C100.625 124.672 97.0375 128.26 97.0375 132.635C97.0375 136.222 94.0625 139.197 90.475 139.197C86.8875 139.197 83.9125 136.222 83.9125 132.635C83.9125 120.997 93.3624 111.547 105 111.547C116.637 111.547 126.087 120.997 126.087 132.635C126.087 142.61 118.737 147.597 116.025 149.435Z"
        fill={colors?.secondaryLightPurple}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M231.957 134.941C207.131 166.98 161.033 172.828 128.994 148.002C96.9553 123.177 91.1077 77.0785 115.933 45.0394C140.759 13.0003 186.857 7.15265 218.896 31.9784C250.935 56.8041 256.783 102.902 231.957 134.941Z"
        fill={colors?.secondaryBackground}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M244.82 163.807C239.036 159.325 230.928 159.801 224.902 163.953C193.533 185.57 150.56 185.538 118.838 160.958C79.5935 130.549 72.4308 74.0832 102.84 34.8384C133.249 -4.40648 189.715 -11.5692 228.959 18.8399C260.681 43.4198 271.442 85.024 258.342 120.797C255.826 127.669 257.389 135.638 263.174 140.121L325.194 188.177C331.734 193.245 332.928 202.656 327.86 209.197C322.792 215.738 313.381 216.932 306.84 211.863L244.82 163.807ZM231.93 134.865C207.096 166.915 160.983 172.765 128.933 147.931C96.8828 123.096 91.0333 76.9828 115.867 44.9328C140.701 12.8829 186.815 7.03328 218.865 31.8674C250.915 56.7015 256.764 102.815 231.93 134.865Z"
        fill={colors?.bg_color}
      />
      <mask
        id="mask0_399_116"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="101"
        y="17"
        width="148"
        height="148"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.126 135.604C208.263 167.692 162.095 173.548 130.008 148.685C97.9206 123.822 92.0642 77.6542 116.927 45.5667C141.791 13.4792 187.958 7.62277 220.046 32.486C252.133 57.3492 257.989 103.517 233.126 135.604Z"
          fill={colors?.bg_color}
        />
      </mask>
      <g mask="url(#mask0_399_116)">
        <path
          d="M155 67.297H55C25 67.297 5 87.297 5 117.297V177.297C5 207.297 25 227.297 55 227.297V248.597C55 256.597 63.9 261.397 70.5 256.897L115 227.297H155C185 227.297 205 207.297 205 177.297V117.297C205 87.297 185 67.297 155 67.297ZM105 185.747C100.8 185.747 97.4999 182.772 97.4999 181.497C97.4999 177.397 100.8 173.997 105 173.997C109.2 173.997 112.5 177.397 112.5 181.497C112.5 185.597 109.2 188.997 105 188.997ZM117.6 147.497C113.7 150.097 112.5 151.797 112.5 154.597V156.697C112.5 160.797 109.1 164.197 105 164.197C100.9 164.197 97.4999 160.797 97.4999 156.697V154.597C97.4999 142.997 106 137.297 109.2 135.097C112.9 132.597 114.1 130.897 114.1 128.297C114.1 123.297 110 119.197 105 119.197C99.9999 119.197 95.8999 123.297 95.8999 128.297C95.8999 132.397 92.5 135.797 88.4 135.797C84.3 135.797 80.9 132.397 80.9 128.297C80.9 114.997 91.6999 104.197 105 104.197C118.3 104.197 129.1 114.997 129.1 128.297C129.1 139.697 120.7 145.397 117.6 147.497Z"
          stroke={colors?.secondaryLightPurple}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeDasharray="12 12"
        />
      </g>
    </svg>
  );
}
