
import React, { useEffect, Children, useMemo, useState } from "react";
import styles from "./index.module.sass";
import classNames from "classnames";
import Button from "../../forms/button/Button";
import { Field, Form, Formik, useField } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  lessonMoveStatuses, 
  progressSelector,
  scoreSelector,
  sendQuizAnswer,
  sendQuizFinish,
  setLessonStatus,
  setProgress,
} from "../../../store/reducers/lesson";
import Modal from "../../UIKit/modal";
import CancelButton from "../../UIKit/buttons/CancelButton/CancelButton"; 
import Typography from "../../UIKit/base/Typography"; 
import { useTranslation } from "react-i18next";
import { 
  RadioButtonIcon,
  TextIcon, 
  CheckBoxButtonGreyIcon,
} from "../../icons"; 
import { FormikValueObserver } from "../../forms/FormikValueObserverControl/FormikValueObserverControl";

export default function FormQuiz({ children, postId, productId }) {
  const [step, setStep] = useState(0);
  const [skip, setSkip] = useState(new Set());
  const [isOpenModal, setIsOpenModal] = useState(false); 
  const progress = useSelector(progressSelector); 
  const score = useSelector(scoreSelector);
  const dispatch = useDispatch();
  const childrenArr = Children.toArray(children);
  const child = childrenArr[step];
  const questionId = child?.props?.question?.id;
  const required = child?.props?.question?.required; 
	const { t } = useTranslation();
 
  const initialState = useMemo(() => {
    return childrenArr.reduce((acc, c) => {
      const { answers, type } = c?.props.question;
      const id = c?.props.question.id;
      const checked = answers.filter((e) => e?.answered);

      if (!acc[id]) {
        acc[id] = [];
      }

      if (checked) {
        acc[id] = checked.map((e) => e.id);
      }
      if (type === "text") {
        acc[id] = answers[0]?.answered ? answers[0]?.title : null;
      }

      return acc;
    }, {});
  }, [childrenArr]);

   

  const currentStep = useMemo(()=> {
	const index = childrenArr.findLastIndex(i =>  i.props.question.answers.find(a =>  a.answered ))
	return index
}, [childrenArr])
 

useEffect(() => { 
	dispatch(
		setProgress({ step: currentStep, finishStep: childrenArr.length - 1 })
	);
  if(currentStep !== -1) {
    setStep(currentStep)
  }
}, [childrenArr.length, dispatch])

 
 

  function validate(values) {
    if (required && !values[questionId]?.length) {
      return ["question_required"];
    }
  }

  function handleCloseModal() {
    setIsOpenModal(false);
  } 
 

  function handleSubmit(values) {
 
    if(child?.props?.question?.type === "text" && 
    (!values[questionId]?.length || !values[questionId]?.[0]) &&
    step !== childrenArr.length - 1) { 
      handleSkip()
      return
    }
    
    if (step !== childrenArr.length - 1) {
      if (skip.has(step)) {
        skip.delete(step);
      }
      dispatch(
        sendQuizAnswer({
          questionId,
          data: values[questionId],
          cb: () => setStep((s) => s + 1),
          lessonId: postId,
          progress: { step: step + 1, finishStep: childrenArr.length - 1 },
        })
      );
    }
    if (step === childrenArr.length - 1) {
      const data = {
        questionId,
        data: values[questionId],
        lessonId: postId,
        progress: { step: step, finishStep: childrenArr.length - 1 },
      };
      if (skip.size) {
        data.cb = () => setIsOpenModal(true);
      } else {
        data.finish = () => {
          dispatch(setLessonStatus(lessonMoveStatuses.COMPLETED));
        };
      }

      if (!values[questionId]?.length) {
        setIsOpenModal(true);
      } else {
        dispatch(sendQuizAnswer(data));
      }
    }
  }

  function handleSkip() {
    if(child?.props?.question?.type === "text"){
      //send empty answer
      dispatch(
        sendQuizAnswer({
          questionId,
          data: [""], 
          lessonId: postId,
          //progress: { step: step + 1, finishStep: childrenArr.length - 1 },
          hideToast: true
        }))
    }
    if (step !== childrenArr.length - 1) {
      setSkip((s) => {
        s.add(step);
        return s;
      });
      setStep((s) => s + 1);
      dispatch(
        setProgress({ step: step + 1, finishStep: childrenArr.length - 1 })
      ); 
    }
  }

  function handlePrevious() {
    setStep((s) => {
      if (s >= 1) {
        return s - 1;
      }
    });
  }

  function handleTab({ target }) {

    const value = parseInt(target.getAttribute("data-value"));

    if (value <= progress?.step) {
      setStep(value);
    }
  }

  function handleFinish() {
    const cb = () => {
      handleCloseModal();
      dispatch(setLessonStatus(lessonMoveStatuses.COMPLETED));
    };
    const data = {
      postId,
      cb,
    };
    dispatch(sendQuizFinish(data));
  }

  return (
    <>
      <Formik
        initialValues={initialState}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ values, isValid }) => {
          return (
            <Form className={styles.wrapper}>
              <QuizProgressBar
                count={childrenArr.length}
                current={step}
                skip={skip}
                onClick={handleTab}
              />
              <div>{child}</div>
              <div className={styles.button__group}>
                {step > 0 && !score && (
                  <>
                    {(
                      <Button
                        className={classNames(styles.button, styles.button__prev)}
                        onClick={handlePrevious}
                        variant="secondary"
                      >
                        previous
                      </Button>
                    )} 
                  </>
                )}
                {((!required && !values[questionId]?.length > 0 &&  step !== childrenArr.length - 1) || 
                (child.props?.question?.type === "text" && step !== childrenArr.length - 1 && 
                (!values[questionId]?.length || !values[questionId]?.[0]))) && (
                    <> 
                      {(
                        <Button className={styles.button} onClick={handleSkip}>
                          skip_question
                        </Button>
                      )}
                    </>
                  )}

                {
                  <>
                    {(
                      <>
                        {/* next question & finish quiz buttons for desktop */}
                        <Button
                          className={styles.button}
                          disabled={
                            required
                              ? !(isValid && values[questionId]?.length)
                              : !isValid
                          }
                          type="submit"
                        >
                          {step === childrenArr.length - 1
                            ? "finish_quiz"
                            : "next_question"}
                        </Button>
                      </>
                    )}
                  </>
                }
              </div>
            </Form>
          );
        }}
      </Formik>
      <Modal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        className={styles.modal}
      >
        {(close) => (
          <>
            <div className={styles.modal__header}>
              <Typography component="span" fontVariant="medium">
                {t("finish_quiz")}
              </Typography>
              <CancelButton className={styles.cancel} onClick={close} />
            </div>
            <Typography
              component="p"
              fontVariant="medium"
              className={styles.modal__content}
            >
              {t("not_all_answers")}
            </Typography>
            <div className={styles.modal__buttons}>
              <Typography
                component="button"
                fontVariant="semiBold"
                type="button"
                onClick={close}
              >
                {t("cancel")}
              </Typography>
              <Typography
                component="button"
                fontVariant="semiBold"
                type="button"
                onClick={handleFinish}
              >
                {t("finish")}
              </Typography>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}

export function FormQuizItem({ question }) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.question__caption}>
        {question?.type === "radio" && (
          <Typography fontVariant="medium" className={styles.question__type}>
            <RadioButtonIcon />
            <span>{t("select_one")}</span>
          </Typography>
        )}
        {question?.type === "text" && (
          <Typography fontVariant="medium" className={styles.question__type}>
            <TextIcon />
            <span>{t("text_answer")}</span>
          </Typography>
        )}
        {question?.type === "checkbox" && (
          <Typography fontVariant="medium" className={styles.question__type}>
            <CheckBoxButtonGreyIcon />
            <span>{t("select_one_or_more")}</span>
          </Typography>
        )}
        {question?.required ? (
          <Typography
            fontVariant="medium"
            className={styles.question__required}
          >
            {t("required")}
          </Typography>
        ) : null}
      </div>
      <Typography fontVariant="medium" className={styles.question}>
        {question.title}
      </Typography>
      {!!question?.desc && 
      <Typography fontVariant="medium" className={styles.question__desc}>
        {question?.desc}
      </Typography>
      }
      {question?.type === "text" && (
        <Textarea answers={question?.answers} name={question.id} />
      )}
      {(question?.type === "radio" || question?.type === "checkbox") && (
        <AnswerList
          answers={question?.answers}
          name={question.id}
          type={question.type}
        />
      )}
    </>
  );
}

function QuizProgressBar({ count, current, skip, onClick }) {
  return (
    <ul className={styles.progress}>
      {Array.from(new Array(count), (_, i) => (
        <li
          key={i}
          data-value={i}
          onClick={onClick}
          className={classNames(
            styles.progress__item,
            { [styles.progress__item_current]: i === current },
            { [styles.progress__item_previous]: i < current && !skip.has(i) }
          )}
        />
      ))}
    </ul>
  );
}

function AnswerList({ answers, name, type }) {
  if (!answers?.length) {
    return null;
  }
  return (
    <ul className={styles.answer__wrapper}>
      <FormikValueObserver dirty={answers?.length}/>
      {answers.map((e) => {
        return (
          <TypeDecorator
            type={type}
            key={`${e.id}-${Math.random()}`}
            name={name}
            value={e.id}
            label={e.title}
            id={e.id}
            answered={e.answered}
          />
        );
      })}
    </ul>
  );
}

function Radio({ name, className, label, value }) {
  const [form, , helpers] = useField(name);
  const htmlFor = `radio-${Math.random()}`;
  const handleChange = ({ target }) => {
    helpers.setValue([+target.value]);
  };
  return (
    <li className={classNames(styles.answer__item, className)}>
      <input
        name={name}
        id={htmlFor}
        type="radio"
        value={value}
        onChange={handleChange}
        checked={form.value[0] === value}
      />
      <label htmlFor={htmlFor} className={styles.radio} />
      <span className={styles.radio__content}>{label}</span>
    </li>
  );
}

function Checkbox({ name, className, label, value, answered }) {
  const [form, , helpers] = useField(name);
  const htmlFor = `checkbox-${Math.random()}`;
  const set = new Set(form.value);
  const checked = useMemo(() => set.has(value), [value, set]);
  const handleChange = ({ target }) => {
    const value = parseInt(target.value);
    if (!set.size) {
      return helpers.setValue([value]);
    }

    if (set.has(value)) {
      set.delete(value);
    } else {
      set.add(value);
    }

    helpers.setValue([...set]);
  };
  return (
    <li className={classNames(styles.answer__item, className)}>
      <input
        name={name}
        id={htmlFor}
        type="checkbox"
        value={value}
        onChange={handleChange}
        checked={checked}
      />
      <label htmlFor={htmlFor} className={styles.checkbox}>
        <svg
          width="14"
          height="10"
          viewBox="0 0 14 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.15308 9.5C4.89295 9.5 4.64582 9.39955 4.46373 9.22375L0.782891 5.67021C0.405703 5.30607 0.405703 4.70335 0.782891 4.3392C1.16008 3.97506 1.78439 3.97506 2.16158 4.3392L5.15308 7.22724L11.8384 0.773108C12.2156 0.408964 12.8399 0.408964 13.2171 0.773108C13.5943 1.13725 13.5943 1.73997 13.2171 2.10412L5.84242 9.22375C5.66033 9.39955 5.41321 9.5 5.15308 9.5Z"
            fill="#FBFAFF"
          />
        </svg>
      </label>
      <span className={styles.radio__content}>{label}</span>
    </li>
  );
}

function Textarea({ name, answers }) {
  const htmlFor = `textarea-${Math.random()}`;
  const [form, , helpers] = useField(name);
  const { t } = useTranslation(); 
  const [value, setValue] = useState(form?.value);

  useEffect(() => {
    !Array.isArray(value) && helpers.setValue([value]);
  }, []) 
  
  const handleChange = ({ target }) => {
    setValue(target.value);
    helpers.setValue(target.value ? [target.value] : []);
  };

  return (
    <>
      <Typography
        component="textarea"
        className={classNames(styles.textarea)}
        name={name}
        id={htmlFor}
        value={value}
        onChange={handleChange}
        maxLength={2048}
		    placeholder={t("enter_your_answer")}
      />
    </>
  );
}

function TypeDecorator({ type, ...props }) {
  if (type === "radio") {
    return <Radio {...props} />;
  }

  if (type === "checkbox") {
    return <Checkbox {...props} />;
  }
}
