import styles from "./404.module.sass"
import { Image404 } from "./404image"
import { useTranslation } from "react-i18next"
import Typography from "../UIKit/base/Typography"
import { Link, Outlet } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
	resetDomainTheme,
	themeSelector,
} from "../../store/reducers/domainTheme"
import {
	authErrorSelector,
	authSelector,
	setToken,
} from "../../store/reducers/auth"
import { useEffect, useMemo } from "react"

const BAD_CODE = [404, 423]

export const Page404Layout = () => {
	const errorStatus = useSelector(authErrorSelector)
	const dispatch = useDispatch()
	const auth = useSelector(authSelector)

	const isError = useMemo(() => {
		return BAD_CODE.includes(errorStatus)
	}, [errorStatus])

	useEffect(() => {
		if (errorStatus === 423) {
			if (auth) {
				localStorage.removeItem("token")
				localStorage.removeItem("refresh_token")
				dispatch(setToken(null))
			}
			dispatch(resetDomainTheme())
		}
	}, [auth, dispatch, errorStatus])

	if (isError) {
		return <Page404 />
	}
	return <Outlet />
}

const Page404 = () => {
	const { t } = useTranslation()
	const theme = useSelector(themeSelector)

	return (
		<Typography className={styles.page404}>
			<Image404 />
			<Typography component="h4">{t("page_not_found")}</Typography>
			<p>{t("page_not_found_text")}</p>

			{theme?.title && (
				<Typography
					component={Link}
					className={styles.page404__button}
					to={`/`}
				>
					{t("back_to_project")}
				</Typography>
			)}
		</Typography>
	)
}

export default Page404
