import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Typography from "../UIKit/base/Typography";
import { ClockIcon, MoreIcon } from "../icons";
import { timeSince } from "../utils";
import styles from "./Notifications.module.sass";
import * as Icons from "./NotificationsIconPack/icons";
import { useMemo, useRef, useState } from "react";
import useClickOutside from "../hooks/useClickOutside";
import classNames from "classnames";
import { dispatch } from '../..';
import { getFileLink } from '../../store/reducers/knowledgeBase';

const NotificationsItem = ({ item }) => {
  const Icon = Icons[item.type];
  const [showSubmenu, setShowSubmenu] = useState(false);
  const ref = useRef(null);
  const { t } = useTranslation();

  useClickOutside(ref, () => setShowSubmenu(false));

  const noLink = useMemo(() => {
    return item?.type === "custom" ||  item?.type === "security"
 
  }, [item?.type]) 

  return (
    <Typography component={noLink ? "div" : Link} to={linkToGenerator(item)} className={classNames(styles.notification, {[styles.notification__noLink]: !!noLink})}>
      <div className={styles.notification__content}>
        <div className={styles[`notification__icon_${item.color}`]}>
          {Icon && <Icon />}
        </div>

        <div className={styles.notification__info}>
          <div className={styles.notification__title}>
            <Typography> {t(item.title)}</Typography>
          </div>
          <div className={styles.notification__text}>
            <TextGenerator item={item} />
          </div>
          <div className={styles.notification__bottom}> 
          {!item.viewed && (
                      <div className={styles.notificationPopup__viewed}></div>
                    )}
          <div className={styles.notification__time}>
            {timeSince(item.sent_at, i18next.language)} {t("ago")}
          </div>
          </div>
        </div>
      </div>

      {item?.type === "custom" &&
      <div className={styles.notification__more}>
        <div
          className={styles.notification__button}
          onClick={() => setShowSubmenu((prev) => !prev)}
        >
          <MoreIcon />
        </div>

        {showSubmenu && (
          <div ref={ref} className={styles.notification__submenu}>
          <div className={styles.notification__submenu_item}>
            <div className={styles.notification__link}>
              <LinkGenerator item={item} />
            </div>
            </div>
          </div>
        )}
      </div>
      }
    </Typography>
  );
};

export default NotificationsItem;

function linkToGenerator(item) { 

  const { type, entity_id, entity_title, link, path_id } = item;

  const customLink =
    link && type === "custom"
      ? link?.split("://")?.[1]
        ? link
        : `https://${link}`
      : "";

 
    
     if(type === "products" && entity_title) {
      return  `/products/${entity_id}${
        path_id ? `?filter[path_id]=${path_id}` : ""
      }`
     }
         
     if(type === "survey") {
      return link
     } 
      if(type === "custom" && customLink) {
        return customLink
      }
      if(type === "kb_doc_access" && link) {
        return link
      }
}


function LinkGenerator(item) {
  const { t } = useTranslation();

	const { type, entity_id, entity_title, link, path_id, document_type, document_name } = item.item

	const handleDownload = (documentId, filename) => {
		dispatch(getFileLink({documentId, filename}))
	}

	const customLink = (link && type === "custom") ? (link?.split("://")?.[1]  ? link : `https://${link}` ) : "";
	const isFile = (!!document_type)
	return (
		<>
			{type === "products" && entity_title && (
				<Link target="_blank" to={`/products/${entity_id}${path_id ? `?filter[path_id]=${path_id}` : ""}`}>
					{t("go_to")} {entity_title}
				</Link>
			)}
			{type === "survey" && <Link to={link} target="_blank">{t("go_survey")}</Link>}
			{type === "custom" && customLink && <a href={customLink} target="_blank">{t("more")}</a>}
			{type === "kb_doc_access" && link && (isFile ? <span onClick={() => handleDownload(entity_id, document_name)}>{t("go_doc")}</span> : <a href={link}>{t("go_doc")}</a>)}
		</>
	)
}

function TextGenerator(item) {
  const { t } = useTranslation();

  const { type, text, action, title } = item.item;
  return (
    <>
      {type === "custom" && <Typography>{text}</Typography>}
      {type !== "custom" &&
        type !== "survey" &&
        type !== "kb_doc_access" &&
        type !== "kb_folder_access" && <Typography>{action}</Typography>}
      {type === "survey" && (
        <Typography>{`${t(action)} "${title}"`}</Typography>
      )}
      {(type === "kb_doc_access" || type === "kb_folder_access") && (
        <Typography>{title}</Typography>
      )}
    </>
  );
}
