import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themeSelector } from "../../../../store/reducers/domainTheme";
import { setColorPalette } from "../../../utils";

const initialColors = {
  primary_color: "#6B4DFF",
  bg_color: "#FBFAFF",
  secondaryBackground: "#F6F5FF",
  secondaryLightPurple: "#E2E0FF",
  secondaryGray: "#A4A1B2",
};

export function Image2(props) {
  const [colors, setColors] = useState(initialColors);

  const theme = useSelector(themeSelector);

  useEffect(() => {
    if (theme?.title) {
      const newColors = setColorPalette(theme);
      setColors(newColors);
    }
  }, [theme]);

  return (
    <svg
      width="456"
      height="325"
      viewBox="0 0 456 325"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18699_43104)">
        <rect
          width="456"
          height="325"
          rx="12"
          fill={colors?.secondaryBackground}
        />
        <g filter="url(#filter0_d_18699_43104)">
          <path
            d="M49.4004 106.876C49.4004 102.864 49.4004 100.858 50.3405 99.3487C51.0352 98.2328 52.0753 97.3146 53.3391 96.7011C55.049 95.8711 57.3208 95.8711 61.8644 95.8711H272.536C277.08 95.8711 279.352 95.8711 281.062 96.7011C282.326 97.3146 283.366 98.2328 284.06 99.3487C285 100.858 285 102.864 285 106.876V298.757C285 302.768 285 304.774 284.06 306.284C283.366 307.4 282.326 308.318 281.062 308.932C279.352 309.762 277.08 309.762 272.536 309.762H61.8644C57.3208 309.762 55.049 309.762 53.3391 308.932C52.0753 308.318 51.0352 307.4 50.3405 306.284C49.4004 304.774 49.4004 302.769 49.4004 298.757V106.876Z"
            fill={colors?.bg_color}
          />
        </g>
        <g filter="url(#filter1_d_18699_43104)">
          <path
            d="M292.6 78.237C292.6 74.1692 292.6 72.1353 293.54 70.6044C294.234 69.4729 295.274 68.5418 296.538 67.9198C298.248 67.0781 300.52 67.0781 305.064 67.0781H394.136C398.679 67.0781 400.951 67.0781 402.661 67.9198C403.925 68.5418 404.965 69.4729 405.66 70.6044C406.6 72.1353 406.6 74.1692 406.6 78.237V298.603C406.6 302.671 406.6 304.705 405.66 306.235C404.965 307.367 403.925 308.298 402.661 308.92C400.951 309.762 398.679 309.762 394.136 309.762H305.064C300.52 309.762 298.248 309.762 296.538 308.92C295.274 308.298 294.234 307.367 293.54 306.235C292.6 304.705 292.6 302.671 292.6 298.603V78.237Z"
            fill={colors?.bg_color}
          />
        </g>
        <path
          d="M297.667 78.3675C297.667 76.0976 297.667 74.9626 298.137 74.1083C298.484 73.477 299.004 72.9574 299.636 72.6103C300.491 72.1406 301.627 72.1406 303.899 72.1406H395.302C397.573 72.1406 398.709 72.1406 399.564 72.6103C400.196 72.9574 400.716 73.477 401.064 74.1083C401.534 74.9626 401.534 76.0976 401.534 78.3675V124.765C401.534 127.035 401.534 128.17 401.064 129.024C400.716 129.656 400.196 130.175 399.564 130.523C398.709 130.992 397.573 130.992 395.302 130.992H303.899C301.627 130.992 300.491 130.992 299.636 130.523C299.004 130.175 298.484 129.656 298.137 129.024C297.667 128.17 297.667 127.035 297.667 124.765V78.3675Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          d="M77.2666 107.262C77.2666 105.165 78.9679 103.465 81.0666 103.465H108.617C110.715 103.465 112.417 105.165 112.417 107.262C112.417 109.359 110.715 111.059 108.617 111.059H81.0666C78.9679 111.059 77.2666 109.359 77.2666 107.262Z"
          fill="#9F9DA5"
        />
        <path
          d="M77.2666 205.348C77.2666 203.251 78.9679 201.551 81.0666 201.551H108.617C110.715 201.551 112.417 203.251 112.417 205.348C112.417 207.445 110.715 209.145 108.617 209.145H81.0666C78.9679 209.145 77.2666 207.445 77.2666 205.348Z"
          fill="#9F9DA5"
        />
        <path
          d="M77.2666 290.777C77.2666 288.68 78.9679 286.98 81.0666 286.98H108.617C110.715 286.98 112.417 288.68 112.417 290.777C112.417 292.874 110.715 294.574 108.617 294.574H81.0666C78.9679 294.574 77.2666 292.874 77.2666 290.777Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.4"
          d="M77.2666 116.121C77.2666 114.723 78.4008 113.59 79.7999 113.59H101.65C103.049 113.59 104.183 114.723 104.183 116.121C104.183 117.519 103.049 118.652 101.65 118.652H79.7999C78.4008 118.652 77.2666 117.519 77.2666 116.121Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.4"
          d="M77.2666 214.207C77.2666 212.809 78.4008 211.676 79.7999 211.676H101.65C103.049 211.676 104.183 212.809 104.183 214.207C104.183 215.605 103.049 216.738 101.65 216.738H79.7999C78.4008 216.738 77.2666 215.605 77.2666 214.207Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.4"
          d="M77.2666 299.637C77.2666 298.239 78.4008 297.105 79.7999 297.105H101.65C103.049 297.105 104.183 298.239 104.183 299.637C104.183 301.035 103.049 302.168 101.65 302.168H79.7999C78.4008 302.168 77.2666 301.035 77.2666 299.637Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.16"
          d="M57 109.692C57 107.422 57 106.287 57.47 105.433C57.8174 104.801 58.3374 104.282 58.9693 103.934C59.8243 103.465 60.9602 103.465 63.232 103.465H65.968C68.2398 103.465 69.3757 103.465 70.2307 103.934C70.8626 104.282 71.3826 104.801 71.73 105.433C72.2 106.287 72.2 107.422 72.2 109.692V112.425C72.2 114.695 72.2 115.83 71.73 116.685C71.3826 117.316 70.8626 117.836 70.2307 118.183C69.3757 118.652 68.2398 118.652 65.968 118.652H63.232C60.9602 118.652 59.8243 118.652 58.9693 118.183C58.3374 117.836 57.8174 117.316 57.47 116.685C57 115.83 57 114.695 57 112.425V109.692Z"
          fill={colors?.primary_color}
        />
        <path
          opacity="0.16"
          d="M57 207.778C57 205.508 57 204.373 57.47 203.518C57.8174 202.887 58.3374 202.368 58.9693 202.02C59.8243 201.551 60.9602 201.551 63.232 201.551H65.968C68.2398 201.551 69.3757 201.551 70.2307 202.02C70.8626 202.368 71.3826 202.887 71.73 203.518C72.2 204.373 72.2 205.508 72.2 207.778V210.511C72.2 212.781 72.2 213.916 71.73 214.771C71.3826 215.402 70.8626 215.922 70.2307 216.269C69.3757 216.738 68.2398 216.738 65.968 216.738H63.232C60.9602 216.738 59.8243 216.738 58.9693 216.269C58.3374 215.922 57.8174 215.402 57.47 214.771C57 213.916 57 212.781 57 210.511V207.778Z"
          fill={colors?.primary_color}
        />
        <path
          opacity="0.16"
          d="M57 293.207C57 290.937 57 289.802 57.47 288.948C57.8174 288.317 58.3374 287.797 58.9693 287.45C59.8243 286.98 60.9602 286.98 63.232 286.98H65.968C68.2398 286.98 69.3757 286.98 70.2307 287.45C70.8626 287.797 71.3826 288.317 71.73 288.948C72.2 289.802 72.2 290.937 72.2 293.207V295.941C72.2 298.211 72.2 299.346 71.73 300.2C71.3826 300.832 70.8626 301.351 70.2307 301.698C69.3757 302.168 68.2398 302.168 65.968 302.168H63.232C60.9602 302.168 59.8243 302.168 58.9693 301.698C58.3374 301.351 57.8174 300.832 57.47 300.2C57 299.346 57 298.211 57 295.941V293.207Z"
          fill={colors?.primary_color}
        />
        <path
          d="M59.5332 111.059C59.5332 109.287 59.5332 108.401 59.8783 107.724C60.1819 107.128 60.6664 106.644 61.2622 106.341C61.9396 105.996 62.8264 105.996 64.5999 105.996C66.3734 105.996 67.2601 105.996 67.9375 106.341C68.5334 106.644 69.0178 107.128 69.3214 107.724C69.6665 108.401 69.6665 109.287 69.6665 111.059C69.6665 112.831 69.6665 113.717 69.3214 114.393C69.0178 114.989 68.5334 115.473 67.9375 115.776C67.2601 116.121 66.3734 116.121 64.5999 116.121C62.8264 116.121 61.9396 116.121 61.2622 115.776C60.6664 115.473 60.1819 114.989 59.8783 114.393C59.5332 113.717 59.5332 112.831 59.5332 111.059Z"
          fill={colors?.primary_color}
        />
        <path
          d="M59.5332 209.145C59.5332 207.372 59.5332 206.486 59.8783 205.81C60.1819 205.214 60.6664 204.73 61.2622 204.427C61.9396 204.082 62.8264 204.082 64.5999 204.082C66.3734 204.082 67.2601 204.082 67.9375 204.427C68.5334 204.73 69.0178 205.214 69.3214 205.81C69.6665 206.486 69.6665 207.372 69.6665 209.145C69.6665 210.917 69.6665 211.803 69.3214 212.479C69.0178 213.075 68.5334 213.559 67.9375 213.862C67.2601 214.207 66.3734 214.207 64.5999 214.207C62.8264 214.207 61.9396 214.207 61.2622 213.862C60.6664 213.559 60.1819 213.075 59.8783 212.479C59.5332 211.803 59.5332 210.917 59.5332 209.145Z"
          fill={colors?.primary_color}
        />
        <path
          d="M59.5332 294.574C59.5332 292.802 59.5332 291.916 59.8783 291.239C60.1819 290.644 60.6664 290.16 61.2622 289.857C61.9396 289.512 62.8264 289.512 64.5999 289.512C66.3734 289.512 67.2601 289.512 67.9375 289.857C68.5334 290.16 69.0178 290.644 69.3214 291.239C69.6665 291.916 69.6665 292.802 69.6665 294.574C69.6665 296.346 69.6665 297.232 69.3214 297.909C69.0178 298.504 68.5334 298.989 67.9375 299.292C67.2601 299.637 66.3734 299.637 64.5999 299.637C62.8264 299.637 61.9396 299.637 61.2622 299.292C60.6664 298.989 60.1819 298.504 59.8783 297.909C59.5332 297.232 59.5332 296.346 59.5332 294.574Z"
          fill={colors?.primary_color}
        />
        <path d="M0 25.3125H456V36.7031H0V25.3125Z" fill={colors?.bg_color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 25.3125V36.7031H456V36.3867H0.316667V25.3125H0Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          d="M242.883 105.996C245.681 105.996 247.95 108.263 247.95 111.059C247.95 113.855 245.681 116.121 242.883 116.121C240.085 116.121 237.816 113.855 237.816 111.059C237.816 108.263 240.085 105.996 242.883 105.996ZM242.883 114.299C244.674 114.299 246.126 112.848 246.126 111.059C246.126 109.269 244.674 107.819 242.883 107.819C241.092 107.819 239.64 109.269 239.64 111.059C239.64 112.848 241.092 114.299 242.883 114.299Z"
          fill="#00CC66"
        />
        <path
          d="M242.883 204.082C245.681 204.082 247.95 206.349 247.95 209.145C247.95 211.94 245.681 214.207 242.883 214.207C240.085 214.207 237.816 211.94 237.816 209.145C237.816 206.349 240.085 204.082 242.883 204.082ZM242.883 212.385C244.674 212.385 246.126 210.934 246.126 209.145C246.126 207.355 244.674 205.905 242.883 205.905C241.092 205.905 239.64 207.355 239.64 209.145C239.64 210.934 241.092 212.385 242.883 212.385Z"
          fill="#00CC66"
        />
        <path
          d="M242.883 289.512C245.681 289.512 247.95 291.778 247.95 294.574C247.95 297.37 245.681 299.637 242.883 299.637C240.085 299.637 237.816 297.37 237.816 294.574C237.816 291.778 240.085 289.512 242.883 289.512ZM242.883 297.814C244.674 297.814 246.126 296.364 246.126 294.574C246.126 292.785 244.674 291.334 242.883 291.334C241.092 291.334 239.64 292.785 239.64 294.574C239.64 296.364 241.092 297.814 242.883 297.814Z"
          fill="#00CC66"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57 125.139C57 125.051 57.0709 124.98 57.1583 124.98H277.242C277.329 124.98 277.4 125.051 277.4 125.139C277.4 125.226 277.329 125.297 277.242 125.297H57.1583C57.0709 125.297 57 125.226 57 125.139Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57 278.596C57 278.508 57.0709 278.438 57.1583 278.438H277.242C277.329 278.438 277.4 278.508 277.4 278.596C277.4 278.683 277.329 278.754 277.242 278.754H57.1583C57.0709 278.754 57 278.683 57 278.596Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57 224.49C57 224.403 57.0709 224.332 57.1583 224.332H277.242C277.329 224.332 277.4 224.403 277.4 224.49C277.4 224.578 277.329 224.648 277.242 224.648H57.1583C57.0709 224.648 57 224.578 57 224.49Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57 193.799C57 193.711 57.0709 193.641 57.1583 193.641H277.242C277.329 193.641 277.4 193.711 277.4 193.799C277.4 193.886 277.329 193.957 277.242 193.957H57.1583C57.0709 193.957 57 193.886 57 193.799Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          d="M379.683 138.586C382.481 138.586 384.75 140.852 384.75 143.648C384.75 146.444 382.481 148.711 379.683 148.711C376.885 148.711 374.616 146.444 374.616 143.648C374.616 140.852 376.885 138.586 379.683 138.586ZM379.683 146.888C381.474 146.888 382.926 145.438 382.926 143.648C382.926 141.859 381.474 140.408 379.683 140.408C377.892 140.408 376.44 141.859 376.44 143.648C376.44 145.438 377.892 146.888 379.683 146.888Z"
          fill="#00CC66"
        />
        <path
          d="M392.35 143.648C392.35 142.25 393.484 141.117 394.883 141.117H399C400.399 141.117 401.533 142.25 401.533 143.648C401.533 145.046 400.399 146.18 399 146.18H394.883C393.484 146.18 392.35 145.046 392.35 143.648Z"
          fill="#9F9DA5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M297.667 156.146C297.667 156.059 297.738 155.988 297.825 155.988H401.375C401.463 155.988 401.534 156.059 401.534 156.146C401.534 156.234 401.463 156.305 401.375 156.305H297.825C297.738 156.305 297.667 156.234 297.667 156.146Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M297.667 196.646C297.667 196.559 297.738 196.488 297.825 196.488H401.375C401.463 196.488 401.534 196.559 401.534 196.646C401.534 196.734 401.463 196.805 401.375 196.805H297.825C297.738 196.805 297.667 196.734 297.667 196.646Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M297.667 252.018C297.667 251.93 297.738 251.859 297.825 251.859H401.375C401.463 251.859 401.534 251.93 401.534 252.018C401.534 252.105 401.463 252.176 401.375 252.176H297.825C297.738 252.176 297.667 252.105 297.667 252.018Z"
          fill={colors?.secondaryLightPurple}
        />
        <path d="M0 0H456V25.3125H0V0Z" fill={colors?.bg_color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0V25.3125H456V24.9961H0.316667V0H0Z"
          fill={colors?.secondaryLightPurple}
        />
        <path
          opacity="0.16"
          d="M406.6 12.6562C406.6 16.8502 403.198 20.25 399 20.25C394.803 20.25 391.4 16.8502 391.4 12.6562C391.4 8.46234 394.803 5.0625 399 5.0625C403.198 5.0625 406.6 8.46234 406.6 12.6562Z"
          fill={colors?.primary_color}
        />
        <path
          d="M402.8 12.6562C402.8 14.7532 401.099 16.4531 399 16.4531C396.902 16.4531 395.2 14.7532 395.2 12.6562C395.2 10.5593 396.902 8.85938 399 8.85938C401.099 8.85938 402.8 10.5593 402.8 12.6562Z"
          fill={colors?.primary_color}
        />
        <rect
          x="49.4004"
          y="8.85938"
          width="52.25"
          height="7.59375"
          rx="3.79688"
          fill={colors?.primary_color}
        />
        <path
          d="M57 51.8906C57 49.7937 58.7013 48.0938 60.8 48.0938H67.7667C69.8653 48.0938 71.5667 49.7937 71.5667 51.8906C71.5667 53.9876 69.8653 55.6875 67.7667 55.6875H60.8C58.7013 55.6875 57 53.9876 57 51.8906Z"
          fill={colors?.primary_color}
        />
        <path
          d="M327.75 294.574C327.75 292.477 329.451 290.777 331.55 290.777H367.333C369.432 290.777 371.133 292.477 371.133 294.574C371.133 296.671 369.432 298.371 367.333 298.371H331.55C329.451 298.371 327.75 296.671 327.75 294.574Z"
          fill={colors?.primary_color}
        />
        <path
          d="M297.667 142.282C297.667 140.012 297.667 138.877 298.137 138.022C298.484 137.391 299.004 136.871 299.636 136.524C300.491 136.055 301.627 136.055 303.899 136.055H352.235C354.507 136.055 355.643 136.055 356.498 136.524C357.13 136.871 357.65 137.391 357.997 138.022C358.467 138.877 358.467 140.012 358.467 142.282V145.015C358.467 147.285 358.467 148.42 357.997 149.274C357.65 149.906 357.13 150.425 356.498 150.773C355.643 151.242 354.507 151.242 352.235 151.242H303.899C301.627 151.242 300.491 151.242 299.636 150.773C299.004 150.425 298.484 149.906 298.137 149.274C297.667 148.42 297.667 147.285 297.667 145.015V142.282Z"
          fill={colors?.primary_color}
        />
        <path
          d="M305.267 143.648C305.267 141.551 306.968 139.852 309.067 139.852H347.067C349.165 139.852 350.867 141.551 350.867 143.648C350.867 145.745 349.165 147.445 347.067 147.445H309.067C306.968 147.445 305.267 145.745 305.267 143.648Z"
          fill={colors?.bg_color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.9351 44.9297H55.6324C54.4853 44.9297 53.6657 44.9302 53.0247 44.986C52.3919 45.0411 51.9931 45.1463 51.6748 45.3211C51.1483 45.6103 50.7149 46.0433 50.4254 46.5695C50.2505 46.8874 50.1452 47.2859 50.0901 47.9182C50.0343 48.5586 50.0337 49.3776 50.0337 50.5238V53.2575C50.0337 54.4036 50.0343 55.2226 50.0901 55.8631C50.1452 56.4953 50.2505 56.8938 50.4254 57.2118C50.7149 57.7379 51.1483 58.1709 51.6748 58.4602C51.9931 58.635 52.3919 58.7402 53.0247 58.7952C53.6657 58.851 54.4853 58.8516 55.6324 58.8516H72.9351C74.0821 58.8516 74.9018 58.851 75.5428 58.7952C76.1756 58.7402 76.5744 58.635 76.8926 58.4602C77.4192 58.1709 77.8525 57.7379 78.142 57.2118C78.317 56.8938 78.4223 56.4953 78.4774 55.8631C78.5332 55.2226 78.5337 54.4036 78.5337 53.2575V50.5238C78.5337 49.3776 78.5332 48.5586 78.4774 47.9182C78.4223 47.2859 78.317 46.8874 78.142 46.5695C77.8525 46.0433 77.4192 45.6103 76.8926 45.3211C76.5744 45.1463 76.1756 45.0411 75.5428 44.986C74.9018 44.9302 74.0821 44.9297 72.9351 44.9297ZM49.8704 46.2646C49.4004 47.1189 49.4004 48.2538 49.4004 50.5238V53.2575C49.4004 55.5274 49.4004 56.6624 49.8704 57.5167C50.2178 58.148 50.7378 58.6676 51.3697 59.0147C52.2247 59.4844 53.3606 59.4844 55.6324 59.4844H72.9351C75.2068 59.4844 76.3427 59.4844 77.1977 59.0147C77.8296 58.6676 78.3496 58.148 78.697 57.5167C79.1671 56.6624 79.1671 55.5274 79.1671 53.2575V50.5238C79.1671 48.2538 79.1671 47.1189 78.697 46.2646C78.3496 45.6332 77.8296 45.1136 77.1977 44.7665C76.3427 44.2969 75.2068 44.2969 72.9351 44.2969H55.6324C53.3606 44.2969 52.2247 44.2969 51.3697 44.7665C50.7378 45.1136 50.2178 45.6332 49.8704 46.2646Z"
          fill={colors?.primary_color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M372.818 287.613H326.382C325.235 287.613 324.416 287.614 323.775 287.67C323.142 287.725 322.743 287.83 322.425 288.005C321.898 288.294 321.465 288.727 321.175 289.253C321 289.571 320.895 289.97 320.84 290.602C320.784 291.242 320.784 292.061 320.784 293.207V295.941C320.784 297.087 320.784 297.906 320.84 298.547C320.895 299.179 321 299.577 321.175 299.895C321.465 300.422 321.898 300.855 322.425 301.144C322.743 301.319 323.142 301.424 323.775 301.479C324.416 301.535 325.235 301.535 326.382 301.535H372.818C373.965 301.535 374.785 301.535 375.426 301.479C376.059 301.424 376.458 301.319 376.776 301.144C377.303 300.855 377.736 300.422 378.025 299.895C378.2 299.577 378.306 299.179 378.361 298.547C378.416 297.906 378.417 297.087 378.417 295.941V293.207C378.417 292.061 378.416 291.242 378.361 290.602C378.306 289.97 378.2 289.571 378.025 289.253C377.736 288.727 377.303 288.294 376.776 288.005C376.458 287.83 376.059 287.725 375.426 287.67C374.785 287.614 373.965 287.613 372.818 287.613ZM320.62 288.948C320.15 289.802 320.15 290.937 320.15 293.207V295.941C320.15 298.211 320.15 299.346 320.62 300.2C320.968 300.832 321.488 301.351 322.12 301.698C322.975 302.168 324.111 302.168 326.382 302.168H372.818C375.09 302.168 376.226 302.168 377.081 301.698C377.713 301.351 378.233 300.832 378.58 300.2C379.05 299.346 379.05 298.211 379.05 295.941V293.207C379.05 290.937 379.05 289.802 378.58 288.948C378.233 288.317 377.713 287.797 377.081 287.45C376.226 286.98 375.09 286.98 372.818 286.98H326.382C324.111 286.98 322.975 286.98 322.12 287.45C321.488 287.797 320.968 288.317 320.62 288.948Z"
          fill={colors?.primary_color}
        />
        <rect
          x="218.5"
          y="10.125"
          width="45.9167"
          height="5.0625"
          rx="2.53125"
          fill="#9F9DA5"
        />
        <path
          d="M49.7168 31.0078C49.7168 29.6098 50.851 28.4766 52.2501 28.4766H72.5168C73.9159 28.4766 75.0501 29.6098 75.0501 31.0078C75.0501 32.4058 73.9159 33.5391 72.5168 33.5391H52.2501C50.851 33.5391 49.7168 32.4058 49.7168 31.0078Z"
          fill="#9F9DA5"
        />
        <path
          d="M49.7168 73.4062C49.7168 71.3093 51.4181 69.6094 53.5168 69.6094H115.9C117.999 69.6094 119.7 71.3093 119.7 73.4062C119.7 75.5032 117.999 77.2031 115.9 77.2031H53.5168C51.4181 77.2031 49.7168 75.5032 49.7168 73.4062Z"
          fill="#9F9DA5"
        />
        <path
          d="M57 136.688C57 134.591 58.7013 132.891 60.8 132.891C62.8987 132.891 64.6 134.591 64.6 136.688C64.6 138.784 62.8987 140.484 60.8 140.484C58.7013 140.484 57 138.784 57 136.688Z"
          fill="#9F9DA5"
        />
        <path
          d="M57 236.039C57 233.942 58.7013 232.242 60.8 232.242C62.8987 232.242 64.6 233.942 64.6 236.039C64.6 238.136 62.8987 239.836 60.8 239.836C58.7013 239.836 57 238.136 57 236.039Z"
          fill="#9F9DA5"
        />
        <path
          d="M57 151.875C57 149.778 58.7013 148.078 60.8 148.078C62.8987 148.078 64.6 149.778 64.6 151.875C64.6 153.972 62.8987 155.672 60.8 155.672C58.7013 155.672 57 153.972 57 151.875Z"
          fill="#9F9DA5"
        />
        <path
          d="M57 251.227C57 249.13 58.7013 247.43 60.8 247.43C62.8987 247.43 64.6 249.13 64.6 251.227C64.6 253.324 62.8987 255.023 60.8 255.023C58.7013 255.023 57 253.324 57 251.227Z"
          fill="#9F9DA5"
        />
        <path
          d="M57 167.062C57 164.966 58.7013 163.266 60.8 163.266C62.8987 163.266 64.6 164.966 64.6 167.062C64.6 169.159 62.8987 170.859 60.8 170.859C58.7013 170.859 57 169.159 57 167.062Z"
          fill="#9F9DA5"
        />
        <path
          d="M57 266.414C57 264.317 58.7013 262.617 60.8 262.617C62.8987 262.617 64.6 264.317 64.6 266.414C64.6 268.511 62.8987 270.211 60.8 270.211C58.7013 270.211 57 268.511 57 266.414Z"
          fill="#9F9DA5"
        />
        <path
          d="M57 182.25C57 180.153 58.7013 178.453 60.8 178.453C62.8987 178.453 64.6 180.153 64.6 182.25C64.6 184.347 62.8987 186.047 60.8 186.047C58.7013 186.047 57 184.347 57 182.25Z"
          fill="#9F9DA5"
        />
        <path
          d="M69.667 136.688C69.667 134.591 71.3683 132.891 73.467 132.891H89.3003C91.399 132.891 93.1003 134.591 93.1003 136.688C93.1003 138.784 91.399 140.484 89.3003 140.484H73.467C71.3683 140.484 69.667 138.784 69.667 136.688Z"
          fill="#9F9DA5"
        />
        <path
          d="M69.667 236.039C69.667 233.942 71.3683 232.242 73.467 232.242H89.3003C91.399 232.242 93.1003 233.942 93.1003 236.039C93.1003 238.136 91.399 239.836 89.3003 239.836H73.467C71.3683 239.836 69.667 238.136 69.667 236.039Z"
          fill="#9F9DA5"
        />
        <path
          d="M69.667 151.875C69.667 149.778 71.3683 148.078 73.467 148.078H89.3003C91.399 148.078 93.1003 149.778 93.1003 151.875C93.1003 153.972 91.399 155.672 89.3003 155.672H73.467C71.3683 155.672 69.667 153.972 69.667 151.875Z"
          fill="#9F9DA5"
        />
        <path
          d="M69.667 251.227C69.667 249.13 71.3683 247.43 73.467 247.43H89.3003C91.399 247.43 93.1003 249.13 93.1003 251.227C93.1003 253.324 91.399 255.023 89.3003 255.023H73.467C71.3683 255.023 69.667 253.324 69.667 251.227Z"
          fill="#9F9DA5"
        />
        <path
          d="M69.667 167.062C69.667 164.966 71.3683 163.266 73.467 163.266H97.8503C99.949 163.266 101.65 164.966 101.65 167.062C101.65 169.159 99.949 170.859 97.8503 170.859H73.467C71.3683 170.859 69.667 169.159 69.667 167.062Z"
          fill="#9F9DA5"
        />
        <path
          d="M69.667 266.414C69.667 264.317 71.3683 262.617 73.467 262.617H97.8503C99.949 262.617 101.65 264.317 101.65 266.414C101.65 268.511 99.949 270.211 97.8503 270.211H73.467C71.3683 270.211 69.667 268.511 69.667 266.414Z"
          fill="#9F9DA5"
        />
        <path
          d="M69.667 182.25C69.667 180.153 71.3683 178.453 73.467 178.453H114.95C117.049 178.453 118.75 180.153 118.75 182.25C118.75 184.347 117.049 186.047 114.95 186.047H73.467C71.3683 186.047 69.667 184.347 69.667 182.25Z"
          fill="#9F9DA5"
        />
        <path
          d="M254.6 136.688C254.6 134.591 256.301 132.891 258.4 132.891H273.6C275.698 132.891 277.4 134.591 277.4 136.688C277.4 138.784 275.698 140.484 273.6 140.484H258.4C256.301 140.484 254.6 138.784 254.6 136.688Z"
          fill="#9F9DA5"
        />
        <path
          d="M297.667 165.164C297.667 163.067 299.368 161.367 301.467 161.367H332.5C334.599 161.367 336.3 163.067 336.3 165.164C336.3 167.261 334.599 168.961 332.5 168.961H301.467C299.368 168.961 297.667 167.261 297.667 165.164Z"
          fill="#9F9DA5"
        />
        <path
          d="M297.667 261.035C297.667 258.938 299.368 257.238 301.467 257.238H316.35C318.449 257.238 320.15 258.938 320.15 261.035C320.15 263.132 318.449 264.832 316.35 264.832H301.467C299.368 264.832 297.667 263.132 297.667 261.035Z"
          fill="#9F9DA5"
        />
        <path
          d="M297.667 205.664C297.667 203.567 299.368 201.867 301.467 201.867H320.784C322.882 201.867 324.584 203.567 324.584 205.664C324.584 207.761 322.882 209.461 320.784 209.461H301.467C299.368 209.461 297.667 207.761 297.667 205.664Z"
          fill="#9F9DA5"
        />
        <path
          d="M319.2 218.32C319.2 216.223 320.902 214.523 323 214.523H355.934C358.032 214.523 359.734 216.223 359.734 218.32C359.734 220.417 358.032 222.117 355.934 222.117H323C320.902 222.117 319.2 220.417 319.2 218.32Z"
          fill="#9F9DA5"
        />
        <path
          d="M314.134 271.793C314.134 270.395 315.268 269.262 316.667 269.262H344.534C345.933 269.262 347.067 270.395 347.067 271.793C347.067 273.191 345.933 274.324 344.534 274.324H316.667C315.268 274.324 314.134 273.191 314.134 271.793Z"
          fill="#9F9DA5"
        />
        <path
          d="M371.134 271.793C371.134 270.395 372.268 269.262 373.667 269.262C375.066 269.262 376.2 270.395 376.2 271.793C376.2 273.191 375.066 274.324 373.667 274.324C372.268 274.324 371.134 273.191 371.134 271.793Z"
          fill={colors?.primary_color}
        />
        <path
          d="M377.467 271.793C377.467 270.395 378.601 269.262 380 269.262C381.399 269.262 382.533 270.395 382.533 271.793C382.533 273.191 381.399 274.324 380 274.324C378.601 274.324 377.467 273.191 377.467 271.793Z"
          fill={colors?.primary_color}
        />
        <path
          d="M383.8 271.793C383.8 270.395 384.934 269.262 386.333 269.262C387.732 269.262 388.866 270.395 388.866 271.793C388.866 273.191 387.732 274.324 386.333 274.324C384.934 274.324 383.8 273.191 383.8 271.793Z"
          fill={colors?.primary_color}
        />
        <path
          d="M390.134 271.793C390.134 270.395 391.268 269.262 392.667 269.262C394.066 269.262 395.2 270.395 395.2 271.793C395.2 273.191 394.066 274.324 392.667 274.324C391.268 274.324 390.134 273.191 390.134 271.793Z"
          fill={colors?.primary_color}
        />
        <path
          d="M396.467 271.793C396.467 270.395 397.601 269.262 399 269.262C400.399 269.262 401.533 270.395 401.533 271.793C401.533 273.191 400.399 274.324 399 274.324C397.601 274.324 396.467 273.191 396.467 271.793Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M319.2 226.547C319.2 225.149 320.334 224.016 321.734 224.016H388.234C389.633 224.016 390.767 225.149 390.767 226.547C390.767 227.945 389.633 229.078 388.234 229.078H321.734C320.334 229.078 319.2 227.945 319.2 226.547Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M297.667 237.938C297.667 236.54 298.801 235.406 300.2 235.406H399C400.399 235.406 401.534 236.54 401.534 237.938C401.534 239.335 400.399 240.469 399 240.469H300.2C298.801 240.469 297.667 239.335 297.667 237.938Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M297.667 176.555C297.667 175.157 298.801 174.023 300.2 174.023H399C400.399 174.023 401.534 175.157 401.534 176.555C401.534 177.953 400.399 179.086 399 179.086H300.2C298.801 179.086 297.667 177.953 297.667 176.555Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M314.134 279.387C314.134 277.989 315.268 276.855 316.667 276.855H399C400.4 276.855 401.534 277.989 401.534 279.387C401.534 280.785 400.4 281.918 399 281.918H316.667C315.268 281.918 314.134 280.785 314.134 279.387Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M297.667 182.883C297.667 181.485 298.801 180.352 300.2 180.352H399C400.399 180.352 401.534 181.485 401.534 182.883C401.534 184.281 400.399 185.414 399 185.414H300.2C298.801 185.414 297.667 184.281 297.667 182.883Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M297.667 244.266C297.667 242.868 298.801 241.734 300.2 241.734H323.634C325.033 241.734 326.167 242.868 326.167 244.266C326.167 245.664 325.033 246.797 323.634 246.797H300.2C298.801 246.797 297.667 245.664 297.667 244.266Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M297.667 189.211C297.667 187.813 298.801 186.68 300.2 186.68H354.667C356.066 186.68 357.2 187.813 357.2 189.211C357.2 190.609 356.066 191.742 354.667 191.742H300.2C298.801 191.742 297.667 190.609 297.667 189.211Z"
          fill="#9F9DA5"
        />
        <path
          d="M379.05 165.164C379.05 163.067 380.751 161.367 382.85 161.367H397.733C399.832 161.367 401.533 163.067 401.533 165.164C401.533 167.261 399.832 168.961 397.733 168.961H382.85C380.751 168.961 379.05 167.261 379.05 165.164Z"
          fill={colors?.primary_color}
        />
        <path
          d="M379.05 261.035C379.05 258.938 380.751 257.238 382.85 257.238H397.733C399.832 257.238 401.533 258.938 401.533 261.035C401.533 263.132 399.832 264.832 397.733 264.832H382.85C380.751 264.832 379.05 263.132 379.05 261.035Z"
          fill={colors?.primary_color}
        />
        <path
          d="M254.6 236.039C254.6 233.942 256.301 232.242 258.4 232.242H273.6C275.698 232.242 277.4 233.942 277.4 236.039C277.4 238.136 275.698 239.836 273.6 239.836H258.4C256.301 239.836 254.6 238.136 254.6 236.039Z"
          fill="#9F9DA5"
        />
        <path
          d="M240.35 151.875C240.35 149.778 242.051 148.078 244.15 148.078H273.6C275.698 148.078 277.4 149.778 277.4 151.875C277.4 153.972 275.698 155.672 273.6 155.672H244.15C242.051 155.672 240.35 153.972 240.35 151.875Z"
          fill="#9F9DA5"
        />
        <path
          d="M240.35 251.227C240.35 249.13 242.051 247.43 244.15 247.43H273.6C275.698 247.43 277.4 249.13 277.4 251.227C277.4 253.324 275.698 255.023 273.6 255.023H244.15C242.051 255.023 240.35 253.324 240.35 251.227Z"
          fill="#9F9DA5"
        />
        <path
          d="M249.217 167.062C249.217 164.966 250.918 163.266 253.017 163.266H273.6C275.699 163.266 277.4 164.966 277.4 167.062C277.4 169.159 275.699 170.859 273.6 170.859H253.017C250.918 170.859 249.217 169.159 249.217 167.062Z"
          fill="#9F9DA5"
        />
        <path
          d="M249.217 266.414C249.217 264.317 250.918 262.617 253.017 262.617H273.6C275.699 262.617 277.4 264.317 277.4 266.414C277.4 268.511 275.699 270.211 273.6 270.211H253.017C250.918 270.211 249.217 268.511 249.217 266.414Z"
          fill="#9F9DA5"
        />
        <path
          d="M249.217 182.25C249.217 180.153 250.918 178.453 253.017 178.453H273.6C275.699 178.453 277.4 180.153 277.4 182.25C277.4 184.347 275.699 186.047 273.6 186.047H253.017C250.918 186.047 249.217 184.347 249.217 182.25Z"
          fill="#9F9DA5"
        />
        <path
          d="M85.1836 31.0078C85.1836 29.6098 86.3178 28.4766 87.7169 28.4766H95.0003C96.3994 28.4766 97.5336 29.6098 97.5336 31.0078C97.5336 32.4058 96.3994 33.5391 95.0003 33.5391H87.7169C86.3178 33.5391 85.1836 32.4058 85.1836 31.0078Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M107.667 31.0078C107.667 29.6098 108.801 28.4766 110.2 28.4766H140.6C141.999 28.4766 143.134 29.6098 143.134 31.0078C143.134 32.4058 141.999 33.5391 140.6 33.5391H110.2C108.801 33.5391 107.667 32.4058 107.667 31.0078Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M272.333 111.059C272.333 109.661 273.467 108.527 274.866 108.527C276.265 108.527 277.4 109.661 277.4 111.059C277.4 112.457 276.265 113.59 274.866 113.59C273.467 113.59 272.333 112.457 272.333 111.059Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M272.333 209.145C272.333 207.747 273.467 206.613 274.866 206.613C276.265 206.613 277.4 207.747 277.4 209.145C277.4 210.543 276.265 211.676 274.866 211.676C273.467 211.676 272.333 210.543 272.333 209.145Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M272.333 294.574C272.333 293.176 273.467 292.043 274.866 292.043C276.265 292.043 277.4 293.176 277.4 294.574C277.4 295.972 276.265 297.105 274.866 297.105C273.467 297.105 272.333 295.972 272.333 294.574Z"
          fill="#9F9DA5"
        />
        <path
          d="M255.55 111.059C255.55 109.661 256.684 108.527 258.083 108.527H262.2C263.599 108.527 264.733 109.661 264.733 111.059C264.733 112.457 263.599 113.59 262.2 113.59H258.083C256.684 113.59 255.55 112.457 255.55 111.059Z"
          fill="#9F9DA5"
        />
        <path
          d="M255.55 209.145C255.55 207.747 256.684 206.613 258.083 206.613H262.2C263.599 206.613 264.733 207.747 264.733 209.145C264.733 210.543 263.599 211.676 262.2 211.676H258.083C256.684 211.676 255.55 210.543 255.55 209.145Z"
          fill="#9F9DA5"
        />
        <path
          d="M255.55 294.574C255.55 293.176 256.684 292.043 258.083 292.043H262.2C263.599 292.043 264.733 293.176 264.733 294.574C264.733 295.972 263.599 297.105 262.2 297.105H258.083C256.684 297.105 255.55 295.972 255.55 294.574Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M49.7168 84.1641C49.7168 82.7661 50.851 81.6328 52.2501 81.6328H76.6335C78.0326 81.6328 79.1668 82.7661 79.1668 84.1641C79.1668 85.562 78.0326 86.6953 76.6335 86.6953H52.2501C50.851 86.6953 49.7168 85.562 49.7168 84.1641Z"
          fill="#9F9DA5"
        />
        <path
          opacity="0.75"
          d="M86.7666 84.1641C86.7666 82.7661 87.9008 81.6328 89.2999 81.6328H113.683C115.082 81.6328 116.217 82.7661 116.217 84.1641C116.217 85.562 115.082 86.6953 113.683 86.6953H89.2999C87.9008 86.6953 86.7666 85.562 86.7666 84.1641Z"
          fill="#9F9DA5"
        />
        <rect
          x="166.25"
          y="10.125"
          width="31.9833"
          height="5.0625"
          rx="2.53125"
          fill={colors?.primary_color}
        />
        <rect
          x="284.684"
          y="10.125"
          width="24.3833"
          height="5.0625"
          rx="2.53125"
          fill="#9F9DA5"
        />
        <rect
          opacity="0.75"
          x="373.667"
          y="8.85938"
          width="7.6"
          height="7.59375"
          rx="3.79688"
          fill="#9F9DA5"
        />
        <path
          opacity="0.16"
          d="M315.4 222.117C315.4 227.01 311.431 230.977 306.534 230.977C301.637 230.977 297.667 227.01 297.667 222.117C297.667 217.224 301.637 213.258 306.534 213.258C311.431 213.258 315.4 217.224 315.4 222.117Z"
          fill={colors?.primary_color}
        />
        <path
          opacity="0.16"
          d="M310.334 274.957C310.334 278.452 307.498 281.285 304 281.285C300.503 281.285 297.667 278.452 297.667 274.957C297.667 271.462 300.503 268.629 304 268.629C307.498 268.629 310.334 271.462 310.334 274.957Z"
          fill={colors?.primary_color}
        />
        <path
          d="M310.966 222.117C310.966 224.564 308.981 226.547 306.533 226.547C304.084 226.547 302.1 224.564 302.1 222.117C302.1 219.671 304.084 217.688 306.533 217.688C308.981 217.688 310.966 219.671 310.966 222.117Z"
          fill={colors?.primary_color}
        />
        <path
          d="M307.166 274.957C307.166 276.704 305.749 278.121 304 278.121C302.251 278.121 300.833 276.704 300.833 274.957C300.833 273.21 302.251 271.793 304 271.793C305.749 271.793 307.166 273.21 307.166 274.957Z"
          fill={colors?.primary_color}
        />
      </g>
      <rect
        x="0.5"
        y="0.5"
        width="455"
        height="324"
        rx="11.5"
        stroke={colors?.secondaryLightPurple}
      />
      <defs>
        <filter
          id="filter0_d_18699_43104"
          x="33.4004"
          y="83.8711"
          width="267.6"
          height="245.891"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_18699_43104"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_18699_43104"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_18699_43104"
          x="276.6"
          y="55.0781"
          width="146"
          height="274.684"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="8" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_18699_43104"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_18699_43104"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_18699_43104">
          <rect width="456" height="325" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
