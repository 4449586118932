import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { sendReview } from "../../store/reducers/reviews"
import ReviewRatingSet from "../Reviews/ReviewRatingSet"
import { dangerMessage } from "../UIKit/alert/alertReducer"
import Typography from "../UIKit/base/Typography"
import { createTextArea } from "../UIKit/textArea/textArea"
import Button from "../forms/button/Button"
import styles from "./Finish.module.sass"
import { FormikValueObserver } from "../forms/FormikValueObserverControl/FormikValueObserverControl"

const FinishReview = () => {
	const [reviewText, setReviewText] = useState("")
	const [reviewRating, setReviewRating] = useState(null)

	const { productId } = useParams()
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	let location = useLocation()
	const state = location?.state

	const handleChange = (e) => {
		setReviewText(e.target.value)
	}

	const handleSendReview = () => {
		if (!reviewText || !reviewRating) {
			dispatch(dangerMessage(t("fill_all_fields")))
			return
		}

		const data = {
			rating: reviewRating,
			review: reviewText,
		}
		dispatch(
			sendReview({
				product_id: productId,
				data,
				handleNavigate: handleNavigate,
			})
		)

		setReviewText("")
		setReviewRating(0)
	}

	const handleNavigate = () => {
		navigate(
			`/products/${productId}${
				state?.pathId
					? `?filter[path_id]=${state?.pathId}`
					: state?.basicPathId
					? `?filter[path_id]=${state?.basicPathId}`
					: ""
			}`,
			{ state }
		)
	}

	return (
		<>
			<div className={`${styles.finishReview} ${styles.finish}`}>
				<div className={styles.finishReview__inner}>
					<FormikValueObserver dirty={reviewRating || reviewText?.length} />
					<Typography component="h3" fontVariant="medium">
						{t("review")}
					</Typography>
					<div className={styles.finishReview__rating}>
						<Typography component="p" fontVariant="light">
							{t("you_can_leave_review")}
						</Typography>

						<ReviewRatingSet
							reviewRating={reviewRating}
							setReviewRating={setReviewRating}
						/>
					</div>

					<div className={styles.finishReview__textarea}>
						{createTextArea(
							{
								name: "review",
								helper: "",
								value: reviewText,
								placeholder: t("enter_review"),
								style: {
									mainType: "",
									height: window.innerWidth <= 768 ? 158 : 72,
									margin: {
										bottom: "bottom",
									},
								},
							},
							handleChange
						)}
					</div>

					<div className={styles.finishReview__buttons}>
						<Typography
							component={Button}
							onClick={handleSendReview}
							className={styles.button__white}
						>
							submit
						</Typography>

						<Button
							className={styles.button__outlined}
							variant="secondary"
							onClick={handleNavigate}
						>
							skip
						</Button>
					</div>
				</div>
			</div>
		</>
	)
}

export default FinishReview
