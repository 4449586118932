import { useContext, useEffect, useMemo } from "react"
import { useFormikContext } from "formik"
import { useBlocker } from "react-router-dom"
import { FormikValueObserverCtx } from "./FormikValueObserverProvider"

const FormikValueObserverControl = (props) => {
	const { dirty } = useFormikContext()

	return <FormikValueObserver dirty={dirty} {...props} />
}

export default FormikValueObserverControl

export function FormikValueObserver({ dirty, excluded = [] }) {
	const exluded = useMemo(() => {
		return excluded
	}, [excluded])

	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		if (
			exluded.includes(currentLocation.pathname) &&
			exluded.includes(nextLocation.pathname)
		) {
			return false
		}
		if (nextLocation?.state?.blocker === "off") {
			delete nextLocation?.state?.blocker
			return false
		}
		return dirty && currentLocation.pathname !== nextLocation.pathname
	})
	const { setBlocker } = useContext(FormikValueObserverCtx)
	useEffect(() => {
		const onBeforeUnload = (e) => {
			if (dirty) {
				e.preventDefault()
				e.returnValue = true
			}
		}

		window.addEventListener("beforeunload", onBeforeUnload)

		return () => {
			window.removeEventListener("beforeunload", onBeforeUnload)
		}
	}, [dirty])

	useEffect(() => {
		setBlocker(blocker)
	}, [blocker, setBlocker])
}
