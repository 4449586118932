import { useState, useEffect } from "react";
import Color from "color";
import { useSelector } from "react-redux";
import { themeSelector } from "../../store/reducers/domainTheme";
import styles from "./500.module.sass";

const initialColors = {
	primary_color: "#6B4DFF",
	bg_color: "#FBFAFF",
	secondaryBackground: "#F6F5FF",
	secondaryLightPurple: "#6b4dff29",
	secondaryGray: "#A4A1B2",
};

export function Image500(props) {
	const [colors, setColors] = useState(initialColors);

	const theme = useSelector(themeSelector);

	useEffect(() => {
		if (theme?.title) {
			const { primary_color } = theme;
			const bg_color = theme.negate
				? Color(theme.bg_color).negate().hex()
				: theme.bg_color;
			const text_color = theme.negate
				? Color(theme.text_color).negate().hex()
				: theme.text_color;

			const pureWhite = theme.negate ? "#000000" : "#ffffff"

			const secondaryGray = Color(pureWhite)
				.mix(Color(bg_color))
				.mix(Color(text_color), 0.4)
				.hex();
			const secondaryLightPurple = Color(pureWhite)
				.mix(Color(bg_color))
				.mix(Color(primary_color), 0.16)
				.hex();
			const secondaryBackground = Color(pureWhite)
				.mix(Color(bg_color))
				.mix(Color(primary_color), 0.04);

			setColors({
				primary_color,
				bg_color,
				secondaryBackground,
				secondaryLightPurple,
				secondaryGray,
			});
		}
	}, [theme]);

	return (
		<div className={styles.page500__imageFull}>
			{/* server image */}
			<div className={styles.page500__imageServer}>
				<svg
					width="284"
					height="364"
					viewBox="0 0 284 364"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect
						x="32"
						y="48"
						width="220"
						height="20"
						fill={colors?.secondaryLightPurple}
					/>
					<rect
						x="32"
						y="158"
						width="220"
						height="20"
						fill={colors?.secondaryLightPurple}
					/>
					<rect
						x="62"
						y="108"
						width="160"
						height="10"
						fill={colors?.secondaryLightPurple}
					/>
					<rect
						x="62"
						y="218"
						width="160"
						height="10"
						fill={colors?.secondaryLightPurple}
					/>
					<g filter="url(#filter0_d_2878_17231)">
						<rect
							x="12"
							y="68"
							width="260"
							height="40"
							rx="12"
							fill={colors?.bg_color}
						/>
					</g>
					<g filter="url(#filter1_d_2878_17231)">
						<rect
							x="12"
							y="178"
							width="260"
							height="40"
							rx="12"
							fill={colors?.bg_color}
						/>
					</g>
					<g filter="url(#filter2_d_2878_17231)">
						<rect
							x="12"
							y="228"
							width="260"
							height="120"
							rx="12"
							fill={colors?.bg_color}
						/>
					</g>
					<rect
						x="42"
						y="258"
						width="60"
						height="60"
						rx="12"
						stroke={colors?.primary_color}
						strokWidth="3"
					/>
					<circle
						cx="72"
						cy="287"
						r="20"
						stroke={colors?.primary_color}
						strokWidth="3"
					/>
					<circle
						cx="72"
						cy="287"
						r="8"
						stroke={colors?.primary_color}
						strokWidth="3"
					/>
					<line
						x1="243.5"
						y1="259.5"
						x2="243.5"
						y2="316.5"
						stroke={colors?.primary_color}
						strokWidth="3"
						strokeLinecap="round"
					/>
					<line
						x1="227.5"
						y1="259.5"
						x2="227.5"
						y2="316.5"
						stroke={colors?.primary_color}
						strokWidth="3"
						strokeLinecap="round"
					/>
					<line
						x1="211.5"
						y1="259.5"
						x2="211.5"
						y2="316.5"
						stroke={colors?.primary_color}
						strokWidth="3"
						strokeLinecap="round"
					/>
					<line
						x1="195.5"
						y1="259.5"
						x2="195.5"
						y2="316.5"
						stroke={colors?.primary_color}
						strokWidth="3"
						strokeLinecap="round"
					/>
					<g filter="url(#filter3_d_2878_17231)">
						<rect
							x="12"
							y="8"
							width="260"
							height="40"
							rx="12"
							fill={colors?.bg_color}
						/>
					</g>
					<circle
						cx="212"
						cy="28"
						r="6"
						stroke={colors?.primary_color}
						strokWidth="3"
					/>
					<circle
						cx="232"
						cy="28"
						r="6"
						stroke={colors?.primary_color}
						strokWidth="3"
					/>
					<circle
						cx="252"
						cy="28"
						r="6"
						stroke={colors?.primary_color}
						strokWidth="3"
					/>
					<line
						x1="28.5"
						y1="21.5"
						x2="85.5"
						y2="21.5"
						stroke={colors?.primary_color}
						strokWidth="3"
						strokeLinecap="round"
					/>
					<line
						x1="28.5"
						y1="31.5"
						x2="85.5"
						y2="31.5"
						stroke={colors?.primary_color}
						strokWidth="3"
						strokeLinecap="round"
					/>
					<g filter="url(#filter4_d_2878_17231)">
						<rect
							x="12"
							y="118"
							width="260"
							height="40"
							rx="12"
							fill={colors?.bg_color}
						/>
					</g>
					<circle
						cx="212"
						cy="138"
						r="6"
						stroke={colors?.primary_color}
						strokWidth="3"
					/>
					<circle
						cx="232"
						cy="138"
						r="6"
						stroke={colors?.primary_color}
						strokWidth="3"
					/>
					<circle
						cx="252"
						cy="138"
						r="6"
						stroke={colors?.primary_color}
						strokWidth="3"
					/>
					<line
						x1="28.5"
						y1="131.5"
						x2="85.5"
						y2="131.5"
						stroke={colors?.primary_color}
						strokWidth="3"
						strokeLinecap="round"
					/>
					<line
						x1="28.5"
						y1="141.5"
						x2="85.5"
						y2="141.5"
						stroke={colors?.primary_color}
						strokWidth="3"
						strokeLinecap="round"
					/>
					<defs>
						<filter
							id="filter0_d_2878_17231"
							x="0"
							y="60"
							width="284"
							height="64"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
								result="hardAlpha"
							/>
							<feOffset dy="4" />
							<feGaussianBlur stdDeviation="6" />
							<feComposite in2="hardAlpha" operator="out" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.04 0"
							/>
							<feBlend
								mode="normal"
								in2="BackgroundImageFix"
								result="effect1_dropShadow_2878_17231"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="effect1_dropShadow_2878_17231"
								result="shape"
							/>
						</filter>
						<filter
							id="filter1_d_2878_17231"
							x="0"
							y="170"
							width="284"
							height="64"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
								result="hardAlpha"
							/>
							<feOffset dy="4" />
							<feGaussianBlur stdDeviation="6" />
							<feComposite in2="hardAlpha" operator="out" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.04 0"
							/>
							<feBlend
								mode="normal"
								in2="BackgroundImageFix"
								result="effect1_dropShadow_2878_17231"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="effect1_dropShadow_2878_17231"
								result="shape"
							/>
						</filter>
						<filter
							id="filter2_d_2878_17231"
							x="0"
							y="220"
							width="284"
							height="144"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
								result="hardAlpha"
							/>
							<feOffset dy="4" />
							<feGaussianBlur stdDeviation="6" />
							<feComposite in2="hardAlpha" operator="out" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.04 0"
							/>
							<feBlend
								mode="normal"
								in2="BackgroundImageFix"
								result="effect1_dropShadow_2878_17231"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="effect1_dropShadow_2878_17231"
								result="shape"
							/>
						</filter>
						<filter
							id="filter3_d_2878_17231"
							x="0"
							y="0"
							width="284"
							height="64"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
								result="hardAlpha"
							/>
							<feOffset dy="4" />
							<feGaussianBlur stdDeviation="6" />
							<feComposite in2="hardAlpha" operator="out" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.04 0"
							/>
							<feBlend
								mode="normal"
								in2="BackgroundImageFix"
								result="effect1_dropShadow_2878_17231"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="effect1_dropShadow_2878_17231"
								result="shape"
							/>
						</filter>
						<filter
							id="filter4_d_2878_17231"
							x="0"
							y="110"
							width="284"
							height="64"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
								result="hardAlpha"
							/>
							<feOffset dy="4" />
							<feGaussianBlur stdDeviation="6" />
							<feComposite in2="hardAlpha" operator="out" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.04 0"
							/>
							<feBlend
								mode="normal"
								in2="BackgroundImageFix"
								result="effect1_dropShadow_2878_17231"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="effect1_dropShadow_2878_17231"
								result="shape"
							/>
						</filter>
					</defs>
				</svg>
			</div>

			{/* plug image */}
			<div className={styles.page500__imagePlug}>
				<svg
					width="300"
					height="260"
					viewBox="0 0 300 260"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect
						opacity="0.75"
						x="223.246"
						y="149.728"
						width="8"
						height="47.0613"
						rx="4"
						transform="rotate(45 223.246 149.728)"
						fill={colors?.secondaryGray}
					/>
					<rect
						opacity="0.75"
						x="248.703"
						y="175.184"
						width="8"
						height="44.9052"
						rx="4"
						transform="rotate(45 248.703 175.184)"
						fill={colors?.secondaryGray}
					/>
					<path
						opacity="0.75"
						fillRule="evenodd"
						clipRule="evenodd"
						d="M164.575 0.499899C164.814 0.499973 165.058 0.500048 165.304 0.500048H299.5V12.5H165.304C158.736 12.5 156.497 12.5439 154.465 13.0655C152.819 13.4881 151.241 14.1417 149.779 15.0067C147.973 16.0745 146.359 17.6266 141.715 22.271L127.743 36.2427L119.258 27.7574L133.23 13.7857C133.404 13.6115 133.576 13.4395 133.745 13.2699C137.676 9.33666 140.398 6.61324 143.67 4.67778C146.108 3.23611 148.738 2.14688 151.481 1.44255C155.164 0.496996 159.014 0.498183 164.575 0.499899Z"
						fill={colors?.secondaryGray}
					/>
					<path
						opacity="0.75"
						fillRule="evenodd"
						clipRule="evenodd"
						d="M181.243 230.743L165.271 246.714C165.097 246.889 164.925 247.06 164.755 247.23C160.825 251.163 158.103 253.887 154.83 255.822C152.392 257.264 149.763 258.353 147.02 259.058C143.337 260.003 139.487 260.002 133.926 260C133.686 260 133.443 260 133.197 260H0V248H133.197C139.765 248 142.004 247.956 144.035 247.435C145.681 247.012 147.259 246.358 148.722 245.493C150.527 244.426 152.141 242.873 156.786 238.229L172.757 222.257L181.243 230.743Z"
						fill={colors?.secondaryGray}
					/>
					<g filter="url(#filter0_d_2878_17224)">
						<path
							d="M128.237 38.8808C128.823 38.2948 128.823 37.3447 128.237 36.7587L117.631 26.1529C117.045 25.5669 116.095 25.5669 115.509 26.1529V26.1529C114.923 26.7389 113.973 26.7389 113.387 26.1529L113.033 25.7989C103.27 16.0358 87.4408 16.0358 77.6777 25.7989L66.9579 36.5186C64.4215 39.055 63.1533 40.3232 62.7235 41.8026C62.4059 42.896 62.4059 44.0571 62.7235 45.1505C63.1533 46.6299 64.4215 47.8981 66.9579 50.4345L103.954 87.4303C106.49 89.9667 107.758 91.2349 109.238 91.6647C110.331 91.9824 111.492 91.9824 112.586 91.6647C114.065 91.2349 115.333 89.9667 117.87 87.4303L128.589 76.7106C138.352 66.9475 138.352 51.1183 128.589 41.3552L128.237 41.0029C127.651 40.4169 127.651 39.4668 128.237 38.8808V38.8808Z"
							fill={colors?.bg_color}
						/>
					</g>
					<g filter="url(#filter1_d_2878_17224)">
						<path
							d="M243.396 215.843C243.724 215.515 243.888 215.351 244.018 215.203C246.003 212.939 246.003 209.555 244.018 207.291C243.888 207.143 243.724 206.979 243.396 206.651L196.727 159.982C196.398 159.653 196.234 159.489 196.087 159.36C193.823 157.375 190.438 157.375 188.174 159.36C188.027 159.489 187.863 159.653 187.534 159.982L184.353 163.163C183.572 163.944 183.572 165.211 184.353 165.993V165.993C185.134 166.774 185.134 168.041 184.353 168.823L171.627 181.549C161.864 191.312 161.864 207.141 171.627 216.904L171.981 217.258C172.567 217.844 172.567 218.793 171.981 219.379V219.379C171.395 219.965 171.395 220.914 171.981 221.5L182.588 232.107C183.174 232.693 184.123 232.693 184.709 232.107V232.107C185.295 231.521 186.244 231.521 186.83 232.107L187.183 232.46C196.946 242.223 212.776 242.223 222.539 232.46L235.62 219.379C236.205 218.794 237.154 218.794 237.74 219.379V219.379C238.325 219.965 239.274 219.965 239.86 219.379L243.396 215.843Z"
							fill={colors?.bg_color}
						/>
					</g>
					<defs>
						<filter
							id="filter0_d_2878_17224"
							x="50.4844"
							y="10.4766"
							width="97.4258"
							height="97.4264"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
								result="hardAlpha"
							/>
							<feOffset dy="4" />
							<feGaussianBlur stdDeviation="6" />
							<feComposite in2="hardAlpha" operator="out" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.04 0"
							/>
							<feBlend
								mode="normal"
								in2="BackgroundImageFix"
								result="effect1_dropShadow_2878_17224"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="effect1_dropShadow_2878_17224"
								result="shape"
							/>
						</filter>
						<filter
							id="filter1_d_2878_17224"
							x="152.305"
							y="149.871"
							width="105.203"
							height="105.912"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
								result="hardAlpha"
							/>
							<feOffset dy="4" />
							<feGaussianBlur stdDeviation="6" />
							<feComposite in2="hardAlpha" operator="out" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.04 0"
							/>
							<feBlend
								mode="normal"
								in2="BackgroundImageFix"
								result="effect1_dropShadow_2878_17224"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="effect1_dropShadow_2878_17224"
								result="shape"
							/>
						</filter>
					</defs>
				</svg>
			</div>

			{/* plug 2 image */}
			<div className={styles.page500__imagePlug2}>
				<svg
					width="83"
					height="228"
					viewBox="0 0 83 228"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M6 111V86.0002C6 72.1931 17.1929 61.0002 31 61.0002V61.0002C32.1046 61.0002 33 60.1048 33 59.0002V58.5002C33 57.1195 34.1193 56.0002 35.5 56.0002H48.5C49.8807 56.0002 51 57.1195 51 58.5002V59.0002C51 60.1048 51.8954 61.0002 53 61.0002V61.0002C66.8071 61.0002 78 72.1931 78 86.0002V111"
						stroke={colors?.secondaryLightPurple}
						strokWidth="4"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeDasharray="16 16"
					/>
					<path
						d="M2 111V122C2 123.105 2.89543 124 4 124C5.10457 124 6 124.895 6 126L5.99999 144C5.99999 157.807 17.1929 169 31 169C32.1046 169 33 169.895 33 171V171.5C33 172.881 34.1193 174 35.5 174H48.5C49.8807 174 51 172.881 51 171.5V171C51 169.895 51.8954 169 53 169C66.8071 169 78 157.807 78 144L78 125.5C78 124.672 78.6716 124 79.5 124C80.3284 124 81 123.328 81 122.5V111H2Z"
						stroke={colors?.secondaryLightPurple}
						strokWidth="4"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeDasharray="16 16"
					/>
					<path
						d="M36 174.5V226M48 174.5V214.5"
						stroke={colors?.secondaryLightPurple}
						strokWidth="4"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeDasharray="16 16"
					/>
					<path
						d="M48 55L48 2M36 55L36 16"
						stroke={colors?.secondaryLightPurple}
						strokWidth="4"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeDasharray="16 16"
					/>
				</svg>
			</div>

			{/* 500 image*/}
			<div className={styles.page500__imagePage}>
				<svg
					{...props}
					width="786"
					height="557"
					viewBox="0 0 786 557"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g filter="url(#filter0_d_2878_17261)">
						<g clipPath="url(#clip0_2878_17261)">
							<rect
								x="20"
								y="14"
								width="746"
								height="516.128"
								fill={colors?.secondaryBackground}
							/>
							<path
								d="M70.1328 14H766.399V55.4336H70.1328V14Z"
								fill={colors?.bg_color}
							/>
							<path d="M20 14H69.7333V531.921H20V14Z" fill={colors?.bg_color} />
							<path
								d="M53.152 34.6905C53.152 39.2613 49.4409 42.9667 44.8631 42.9667C40.2853 42.9667 36.5742 39.2613 36.5742 34.6905C36.5742 30.1197 40.2853 26.4143 44.8631 26.4143C49.4409 26.4143 53.152 30.1197 53.152 34.6905Z"
								fill={colors?.primary_color}
							/>
							<path
								opacity="0.75"
								d="M51.0818 105.146C51.0818 108.574 48.2985 111.353 44.8651 111.353C41.4317 111.353 38.6484 108.574 38.6484 105.146C38.6484 101.718 41.4317 98.939 44.8651 98.939C48.2985 98.939 51.0818 101.718 51.0818 105.146Z"
								fill={colors?.secondaryGray}
							/>
							<path
								d="M51.0818 71.9992C51.0818 75.4273 48.2985 78.2064 44.8651 78.2064C41.4317 78.2064 38.6484 75.4273 38.6484 71.9992C38.6484 68.5711 41.4317 65.7921 44.8651 65.7921C48.2985 65.7921 51.0818 68.5711 51.0818 71.9992Z"
								fill={colors?.primary_color}
							/>
							<path
								opacity="0.75"
								d="M51.0818 138.293C51.0818 141.721 48.2985 144.5 44.8651 144.5C41.4317 144.5 38.6484 141.721 38.6484 138.293C38.6484 134.865 41.4317 132.086 44.8651 132.086C48.2985 132.086 51.0818 134.865 51.0818 138.293Z"
								fill={colors?.secondaryGray}
							/>
							<path
								opacity="0.75"
								d="M51.0818 171.247C51.0818 174.675 48.2985 177.454 44.8651 177.454C41.4317 177.454 38.6484 174.675 38.6484 171.247C38.6484 167.819 41.4317 165.04 44.8651 165.04C48.2985 165.04 51.0818 167.819 51.0818 171.247Z"
								fill={colors?.secondaryGray}
							/>
							<path
								opacity="0.75"
								d="M51.0818 204.352C51.0818 207.78 48.2985 210.559 44.8651 210.559C41.4317 210.559 38.6484 207.78 38.6484 204.352C38.6484 200.924 41.4317 198.145 44.8651 198.145C48.2985 198.145 51.0818 200.924 51.0818 204.352Z"
								fill={colors?.secondaryGray}
							/>
							<path
								opacity="0.75"
								d="M51.0818 237.457C51.0818 240.885 48.2985 243.664 44.8651 243.664C41.4317 243.664 38.6484 240.885 38.6484 237.457C38.6484 234.028 41.4317 231.249 44.8651 231.249C48.2985 231.249 51.0818 234.028 51.0818 237.457Z"
								fill={colors?.secondaryGray}
							/>
							<path
								d="M20 65.7922H21.0361C21.6083 65.7922 22.0722 66.2554 22.0722 66.8268V77.172C22.0722 77.7433 21.6083 78.2065 21.0361 78.2065H20V65.7922Z"
								fill={colors?.primary_color}
							/>
							<path
								opacity="0.16"
								d="M57.3003 505.235C57.3003 512.091 51.7337 517.649 44.8669 517.649C38.0002 517.649 32.4336 512.091 32.4336 505.235C32.4336 498.379 38.0002 492.821 44.8669 492.821C51.7337 492.821 57.3003 498.379 57.3003 505.235Z"
								fill={colors?.primary_color}
							/>
							<path
								d="M51.0818 505.235C51.0818 508.663 48.2985 511.442 44.8651 511.442C41.4317 511.442 38.6484 508.663 38.6484 505.235C38.6484 501.807 41.4317 499.028 44.8651 499.028C48.2985 499.028 51.0818 501.807 51.0818 505.235Z"
								fill={colors?.primary_color}
							/>
							<path
								opacity="0.4"
								d="M683.113 34.6904C683.113 38.1185 680.33 40.8975 676.896 40.8975C673.463 40.8975 670.68 38.1185 670.68 34.6904C670.68 31.2623 673.463 28.4833 676.896 28.4833C680.33 28.4833 683.113 31.2623 683.113 34.6904Z"
								fill={colors?.secondaryGray}
							/>
							<path
								opacity="0.4"
								d="M712.121 34.6904C712.121 38.1185 709.338 40.8975 705.904 40.8975C702.471 40.8975 699.688 38.1185 699.688 34.6904C699.688 31.2623 702.471 28.4833 705.904 28.4833C709.338 28.4833 712.121 31.2623 712.121 34.6904Z"
								fill={colors?.secondaryGray}
							/>
							<path
								opacity="0.16"
								d="M753.566 34.6904C753.566 41.5466 747.999 47.1046 741.133 47.1046C734.266 47.1046 728.699 41.5466 728.699 34.6904C728.699 27.8342 734.266 22.2761 741.133 22.2761C747.999 22.2761 753.566 27.8342 753.566 34.6904Z"
								fill={colors?.primary_color}
							/>
							<path
								d="M747.351 34.6904C747.351 38.1185 744.568 40.8975 741.135 40.8975C737.701 40.8975 734.918 38.1185 734.918 34.6904C734.918 31.2623 737.701 28.4833 741.135 28.4833C744.568 28.4833 747.351 31.2623 747.351 34.6904Z"
								fill={colors?.primary_color}
							/>
							<path
								d="M82.168 34.6905C82.168 30.1197 85.879 26.4143 90.4569 26.4143H186.815C191.393 26.4143 195.104 30.1197 195.104 34.6905V34.6905C195.104 39.2613 191.393 42.9667 186.815 42.9667H90.4569C85.879 42.9667 82.168 39.2613 82.168 34.6905V34.6905Z"
								fill={colors?.secondaryGray}
							/>
							<path
								opacity="0.16"
								fillRule="evenodd"
								clipRule="evenodd"
								d="M766 55.433H20V54.9158H766V55.433Z"
								fill={colors?.primary_color}
							/>
							<path
								opacity="0.16"
								fillRule="evenodd"
								clipRule="evenodd"
								d="M69.2153 479.889H20V479.372H69.2153V479.889Z"
								fill={colors?.primary_color}
							/>
							<path
								opacity="0.16"
								fillRule="evenodd"
								clipRule="evenodd"
								d="M69.2163 54.9157L69.2163 14L69.7344 14L69.7344 54.9157L69.2163 54.9157Z"
								fill={colors?.primary_color}
							/>
							<path
								opacity="0.16"
								fillRule="evenodd"
								clipRule="evenodd"
								d="M69.2163 531.402L69.2163 55.4336L69.7344 55.4336L69.7344 531.402L69.2163 531.402Z"
								fill={colors?.primary_color}
							/>
							<path
								d="M231 261.5H209.4L212.1 223.7H292.8V185H173.7L165.3 301.7H228.9C249.3 301.7 261.3 313.4 261.3 330.2C261.3 347.3 249.3 358.7 227.7 358.7C206.7 358.7 195 349.7 189.9 333.5L154.5 354.2C166.5 384.8 195 399.2 227.7 399.2C267.3 399.2 302.7 375.2 302.7 330.2C302.7 285.2 269.4 261.5 231 261.5ZM404.098 399.2C457.798 399.2 488.098 354.8 488.098 290C488.098 225.2 457.798 180.8 404.098 180.8C350.698 180.8 320.098 225.2 320.098 290C320.098 354.8 350.698 399.2 404.098 399.2ZM404.098 358.7C375.898 358.7 361.498 334.4 361.498 290C361.498 245.6 375.898 221 404.098 221C432.298 221 446.998 245.6 446.998 290C446.998 334.4 432.298 358.7 404.098 358.7ZM593.063 399.2C646.763 399.2 677.063 354.8 677.063 290C677.063 225.2 646.763 180.8 593.063 180.8C539.663 180.8 509.063 225.2 509.063 290C509.063 354.8 539.663 399.2 593.063 399.2ZM593.063 358.7C564.863 358.7 550.463 334.4 550.463 290C550.463 245.6 564.863 221 593.063 221C621.263 221 635.963 245.6 635.963 290C635.963 334.4 621.263 358.7 593.063 358.7Z"
								fill={colors?.primary_color}
							/>
						</g>
					</g>
					<defs>
						<filter
							id="filter0_d_2878_17261"
							x="0"
							y="0"
							width="786"
							height="557"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
								result="hardAlpha"
							/>
							<feOffset dy="6" />
							<feGaussianBlur stdDeviation="10" />
							<feComposite in2="hardAlpha" operator="out" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.08 0"
							/>
							<feBlend
								mode="normal"
								in2="BackgroundImageFix"
								result="effect1_dropShadow_2878_17261"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="effect1_dropShadow_2878_17261"
								result="shape"
							/>
						</filter>
						<clipPath id="clip0_2878_17261">
							<rect
								x="20"
								y="14"
								width="746"
								height="517"
								rx="20"
								fill="white"
							/>
						</clipPath>
					</defs>
				</svg>
			</div>
		</div>
	);
}
