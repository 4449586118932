import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themeSelector } from "../../store/reducers/domainTheme";
import { setColorPalette } from "../utils";

const initialColors = {
  primary_color: "#6B4DFF",
  bg_color: "#FBFAFF",
  secondaryBackground: "#F6F5FF",
  secondaryLightPurple: "#E2E0FF",
  secondaryGray: "#A4A1B2",
};

export function NoItemsImage(props) {
  const [colors, setColors] = useState(initialColors);

  const theme = useSelector(themeSelector);

  useEffect(() => {
    if (theme?.title) {
      const newColors = setColorPalette(theme);
      setColors(newColors);
    }
  }, [theme]);

  return (
    <svg
      width="166"
      height="134"
      viewBox="0 0 166 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.6125 92.3937L80.2375 85.1312C79.3187 83.5125 78.4875 80.45 78.4875 78.6562V67.5875C78.4875 57.3063 72.45 48.425 63.7437 44.2688C61.4687 40.2438 57.2687 37.75 52.4562 37.75C47.6875 37.75 43.4 40.3313 41.125 44.4C32.5937 48.6438 26.6875 57.4375 26.6875 67.5875V78.6562C26.6875 80.45 25.8562 83.5125 24.9375 85.0875L20.5187 92.3937C18.7687 95.325 18.375 98.5625 19.4687 101.537C20.5187 104.469 23.0125 106.744 26.25 107.837C34.7375 110.725 43.6625 112.125 52.5875 112.125C61.5125 112.125 70.4375 110.725 78.925 107.881C81.9875 106.875 84.35 104.556 85.4875 101.537C86.625 98.5187 86.3187 95.1937 84.6125 92.3937Z"
        fill={colors?.secondaryLightPurple}
      />
      <path
        d="M64.8812 116.544C63.0437 121.619 58.1875 125.25 52.5 125.25C49.0437 125.25 45.6312 123.85 43.225 121.356C41.825 120.044 40.775 118.294 40.1625 116.5C40.7312 116.587 41.3 116.631 41.9125 116.719C42.9187 116.85 43.9687 116.981 45.0187 117.069C47.5125 117.287 50.05 117.419 52.5875 117.419C55.0812 117.419 57.575 117.287 60.025 117.069C60.9437 116.981 61.8625 116.937 62.7375 116.806C63.4375 116.719 64.1375 116.631 64.8812 116.544Z"
        fill={colors?.secondaryLightPurple}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.98 67.4707C103.567 83.4903 80.5177 86.4141 64.4982 74.0012C48.4786 61.5884 45.5548 38.5393 57.9677 22.5198C70.3805 6.50019 93.4296 3.57638 109.449 15.9893C125.469 28.4021 128.392 51.4512 115.98 67.4707Z"
        fill={colors?.secondaryBackground}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.41 81.9035C119.518 79.6624 115.464 79.9005 112.451 81.9767C96.7666 92.785 75.2801 92.7691 59.4192 80.4791C39.7968 65.2745 36.2154 37.0416 51.4199 17.4192C66.6245 -2.20324 94.8573 -5.78462 114.48 9.41996C130.341 21.7099 135.721 42.512 129.171 60.3985C127.913 63.8344 128.695 67.8192 131.587 70.0603L162.597 94.0886C165.867 96.6227 166.464 101.328 163.93 104.599C161.396 107.869 156.69 108.466 153.42 105.932L122.41 81.9035ZM115.965 67.4326C103.548 83.4576 80.4913 86.3824 64.4664 73.9653C48.4414 61.5483 45.5166 38.4914 57.9337 22.4664C70.3507 6.44144 93.4075 3.51664 109.432 15.9337C125.457 28.3508 128.382 51.4076 115.965 67.4326Z"
        fill={colors?.bg_color}
      />
      <mask
        id="mask0_399_120"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="50"
        y="8"
        width="75"
        height="75"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.563 67.8021C104.132 83.8459 81.0477 86.7741 65.004 74.3425C48.9603 61.9109 46.0321 38.827 58.4637 22.7833C70.8953 6.73954 93.9791 3.81132 110.023 16.2429C126.066 28.6745 128.995 51.7584 116.563 67.8021Z"
          fill={colors?.bg_color}
        />
      </mask>
      <g mask="url(#mask0_399_120)">
        <path
          d="M89.1999 93.95L84.1999 85.65C83.1499 83.8 82.1999 80.3 82.1999 78.25V65.6C82.1999 53.85 75.2999 43.7 65.3499 C62.7999 72.5 80.2999 71.85 95.9999 63.9"
          stroke="#E2E0FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeDasharray="6 6"
        />
      </g>
    </svg>
  );
}
