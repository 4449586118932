import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themeSelector } from "../../store/reducers/domainTheme";
import { setColorPalette } from "../utils";


//{colors?.secondaryLightPurple} {colors?.secondaryBackground} {colors?.bg_color}

const initialColors = {
	primary_color: '#6B4DFF',
	bg_color: '#FBFAFF',
	secondaryBackground: '#F6F5FF',
	secondaryLightPurple: '#E2E0FF',
	secondaryGray: '#A4A1B2',
};

export const NoSkillsImage = () => {

  const [colors, setColors] = useState(initialColors);

	const theme = useSelector(themeSelector);

	useEffect(() => {
		if (theme?.title) {
			const newColors = setColorPalette(theme);
			setColors(newColors);
		}
	}, [theme]);


  return (
    <svg
      width="346"
      height="286"
      viewBox="0 0 346 286"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_14973_36699)">
        <path
          d="M98.4375 228.687H78.75C69.125 228.687 61.25 236.562 61.25 246.187V248.375H52.5C48.9125 248.375 45.9375 251.35 45.9375 254.937C45.9375 258.525 48.9125 261.5 52.5 261.5H157.5C161.087 261.5 164.062 258.525 164.062 254.937C164.062 251.35 161.087 248.375 157.5 248.375H148.75V246.187C148.75 236.562 140.875 228.687 131.25 228.687H111.562V208.65C109.375 208.912 107.187 209 105 209C102.812 209 100.625 208.912 98.4375 208.65V228.687Z"
          fill={colors?.secondaryLightPurple}
        />
      </g>
      <g filter="url(#filter1_d_14973_36699)">
        <path
          d="M161.699 170.85C167.474 168.662 172.549 165.075 176.574 161.05C184.712 152.037 190.049 141.275 190.049 128.675C190.049 116.075 180.162 106.187 167.562 106.187H162.662C156.974 94.55 145.074 86.5 131.249 86.5H78.7492C64.9242 86.5 53.0242 94.55 47.3367 106.187H42.4367C29.8367 106.187 19.9492 116.075 19.9492 128.675C19.9492 141.275 25.2867 152.037 33.4242 161.05C37.4492 165.075 42.5242 168.662 48.2992 170.85C57.3992 193.25 79.2742 209 104.999 209C130.724 209 152.599 193.25 161.699 170.85ZM129.849 142.937L124.424 149.587C123.549 150.55 122.937 152.475 123.024 153.787L123.549 162.362C123.899 167.612 120.137 170.325 115.237 168.4L107.274 165.25C106.049 164.812 103.949 164.812 102.724 165.25L94.7617 168.4C89.8617 170.325 86.0992 167.612 86.4492 162.362L86.9742 153.787C87.0617 152.475 86.4492 150.55 85.5742 149.587L80.1492 142.937C76.7367 138.912 78.2242 134.45 83.2992 133.137L91.6117 131.037C92.9242 130.687 94.4992 129.462 95.1992 128.325L99.8367 121.15C102.724 116.687 107.274 116.687 110.162 121.15L114.799 128.325C115.499 129.462 117.074 130.687 118.387 131.037L126.699 133.137C131.774 134.45 133.262 138.912 129.849 142.937Z"
          fill={colors?.secondaryLightPurple}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M231.957 145.941C207.131 177.98 161.033 183.828 128.994 159.002C96.9553 134.177 91.1077 88.0785 115.933 56.0394C140.759 24.0003 186.857 18.1526 218.896 42.9784C250.935 67.8041 256.783 113.902 231.957 145.941Z"
        fill={colors?.secondaryBackground}
      />
      <g filter="url(#filter2_d_14973_36699)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M244.82 174.807C239.036 170.325 230.928 170.801 224.902 174.953C193.533 196.57 150.56 196.538 118.838 171.958C79.5935 141.549 72.4308 85.0832 102.84 45.8384C133.249 6.59352 189.715 -0.569233 228.959 29.8399C260.681 54.4198 271.442 96.024 258.342 131.797C255.826 138.669 257.389 146.638 263.174 151.121L325.194 199.177C331.734 204.245 332.928 213.656 327.86 220.197C322.792 226.738 313.381 227.932 306.84 222.863L244.82 174.807ZM231.93 145.865C207.096 177.915 160.983 183.765 128.933 158.931C96.8828 134.096 91.0333 87.9828 115.867 55.9328C140.701 23.8829 186.815 18.0333 218.865 42.8674C250.915 67.7015 256.764 113.815 231.93 145.865Z"
          fill={colors?.bg_color}
        />
      </g>
      <mask
        id="mask0_14973_36699"
        style={{maskType: "alpha"}}
        maskUnits="userSpaceOnUse"
        x="101"
        y="28"
        width="148"
        height="148"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.126 146.604C208.263 178.692 162.095 184.548 130.008 159.685C97.9206 134.822 92.0642 88.6542 116.927 56.5667C141.791 24.4792 187.958 18.6228 220.046 43.486C252.133 68.3492 257.989 114.517 233.126 146.604Z"
          fill={colors?.bg_color}
        />
      </mask>
      <g mask="url(#mask0_14973_36699)">
        <path
          d="M169.8 170.4C176.4 167.9 182.2 163.8 186.8 159.2C196.1 148.9 202.2 136.6 202.2 122.2C202.2 107.8 190.9 96.5 176.5 96.5H170.9C164.4 83.2 150.8 74 135 74H74.9998C59.1998 74 45.5998 83.2 39.0998 96.5H33.4998C19.0998 96.5 7.7998 107.8 7.7998 122.2C7.7998 136.6 13.8998 148.9 23.1998 159.2C27.7998 163.8 33.5998 167.9 40.1998 170.4C50.5998 196 75.5998 214 105 214C134.4 214 159.4 196 169.8 170.4ZM133.4 138.5L127.2 146.1C126.2 147.2 125.5 149.4 125.6 150.9L126.2 160.7C126.6 166.7 122.3 169.8 116.7 167.6L107.6 164C106.2 163.5 103.8 163.5 102.4 164L93.2997 167.6C87.6997 169.8 83.3998 166.7 83.7998 160.7L84.3997 150.9C84.4997 149.4 83.7997 147.2 82.7997 146.1L76.5998 138.5C72.6998 133.9 74.3998 128.8 80.1998 127.3L89.6997 124.9C91.1997 124.5 92.9997 123.1 93.7997 121.8L99.0997 113.6C102.4 108.5 107.6 108.5 110.9 113.6L116.2 121.8C117 123.1 118.8 124.5 120.3 124.9L129.8 127.3C135.6 128.8 137.3 133.9 133.4 138.5Z"
          stroke={colors?.secondaryLightPurple}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeDasharray="12 12"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_14973_36699"
          x="27.9375"
          y="196.65"
          width="154.125"
          height="88.8498"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="8.99999" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_14973_36699"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_14973_36699"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_14973_36699"
          x="1.94923"
          y="74.5"
          width="206.1"
          height="158.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="8.99999" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_14973_36699"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_14973_36699"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_14973_36699"
          x="69.0176"
          y="0.512286"
          width="276.965"
          height="244.968"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.49473" />
          <feGaussianBlur stdDeviation="7.49122" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.419608 0 0 0 0 0.301961 0 0 0 0 1 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_14973_36699"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_14973_36699"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
