import styles from "./Layout.module.sass"
import { Outlet, useLocation } from "react-router-dom"
import JustifyContainer from "./JustifyContainer"
import Header from "./Header"
import Footer from "./Footer/Footer"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import { useDispatch, useSelector } from "react-redux"
import { themeSelector } from "../../store/reducers/domainTheme"
import Logo from "../Logo/Logo"
import Typography from "../UIKit/base/Typography"
import { useEffect } from "react"
import { getLastNotifications } from "../../store/reducers/notifications"

const Layout = () => {
	const theme = useSelector(themeSelector)
	const location = useLocation()
	const path = location?.pathname
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getLastNotifications())
	}, [path, dispatch])

	return (
		<Typography className={styles.wrapper}>
			<Header logo={theme?.logo?.small} />
			{window.innerWidth >= 768 && <Breadcrumbs />}
			<JustifyContainer className={styles.app}>
				<Outlet />
			</JustifyContainer>
			<Footer
				logo={Logo}
				email={theme?.support_email}
				phone={theme?.support_phone}
				showLogo={theme?.show_logo}
			/>
		</Typography>
	)
}

export { Layout }
