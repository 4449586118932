import Color from "color";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themeSelector } from "../../store/reducers/domainTheme";
import { setColorPalette } from "../utils";

const initialColors = {
  primary_color: "#6B4DFF",
  bg_color: "#FBFAFF",
  secondaryBackground: "#F6F5FF",
  secondaryLightPurple: "#6b4dff29",
  secondaryGray: "#A4A1B2",
};

export function PreloaderImage(props) {
  const [colors, setColors] = useState(initialColors);

  const theme = useSelector(themeSelector);
  useEffect(() => {
    if (theme?.title) {
        const newColors = setColorPalette(theme);
        setColors(newColors);
    }
}, [theme]);


  return (
    <svg
    {...props}
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M188.606 100C194.899 100 200.067 94.8824 199.352 88.6307C197.386 71.4514 190.989 55.0052 180.701 40.9463C168.161 23.81 150.493 11.1102 130.253 4.68611C110.014 -1.73796 88.2553 -1.55274 68.1284 5.21497C48.0015 11.9827 30.5513 24.9815 18.3053 42.3288C6.05918 59.6761 -0.347009 80.4712 0.0144926 101.702C0.375994 122.934 7.48642 143.499 20.316 160.419C33.1455 177.339 51.0281 189.737 71.3737 195.815C88.0657 200.802 105.704 201.324 122.55 197.424C128.681 196.005 131.773 189.422 129.666 183.493C127.559 177.563 121.048 174.555 114.873 175.767C102.641 178.168 89.9491 177.582 77.8968 173.982C62.1874 169.288 48.3797 159.716 38.4737 146.651C28.5676 133.587 23.0774 117.708 22.7983 101.315C22.5192 84.9213 27.4656 68.8647 36.9211 55.4704C46.3767 42.076 59.8504 32.0393 75.391 26.8137C90.9316 21.5882 107.732 21.4452 123.359 26.4054C138.987 31.3656 152.629 41.1715 162.312 54.403C169.74 64.554 174.541 76.317 176.374 88.6474C177.299 94.8715 182.314 100 188.606 100Z"
        fill={colors?.secondaryLightPurple}
      />
    </svg>
  );
}
